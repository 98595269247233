<template>
  <div class="m-lim-width m-art-comment m-child-comment" :class="first ? 'm-child-first-comment' : ''">
    <div class="m-box">
      <Avatar
        :user="user"
        :anonymity="comment.anonymous == 1"
        :is_anonymous="is_anonymous"
        size="small"
        :old_name="userName"
        :check="true"
      />
      <section class="m-box-model m-flex-grow1 m-flex-shrink1 m-art-comment-body">
        <div>
          <header class="m-box m-aln-center m-justify-bet m-art-comment-usr">
            <h4 class="m-flex-grow1 m-flex-shrink1">
              <!-- {{ user.name }} -->
              {{ userName }}<UserTitle v-if="!anonymous" :user="user" />
              <img
                v-if="comment.excellent_at"
                :src="require('@/images/wonderful@2x.png')"
                class="wonderful"
              >
            </h4>
            <div class="m-box m-aln-center" @click="handelClick">
              <!-- <span v-if="pinned" class="m-art-comment-icon-top">置顶</span> -->
              <img
                :src="require('@/images/detail_more@2x.png')"
                class="m-more"
              >
            </div>
          </header>
          <article :class="{maxh: !isShowAll}" class="m-text-box m-art-comment-con">
            <template v-if="replyUser">
              <span v-if="!replyAnonymous" class="m-art-comment-rep">
                <!--                回复 <RouterLink :to="`/users/${replyUser.id}`" >{{ $getUserName(replyUser, replyAnonymous, comment.reply_username) }}</RouterLink>：-->
                回复 <a href="#" @click.prevent.stop="viewUser()">{{ $getUserName(replyUser, replyAnonymous, comment.reply_username) }}</a>：
              </span>
              <span v-else class="m-art-comment-rep">回复 {{ $getUserName(replyUser, replyAnonymous, comment.reply_username) }}：</span>
            </template>
            <p class="m-text-box" v-html="formatBody(body)" />
            <span
              v-show="bodyLength > 60 && !isShowAll"
              class="m-text-more"
              @click.stop="isShowAll = !isShowAll"
            >
              >> 更多
            </span>
          </article>
        </div>
        <div v-if="comment.attachments && comment.attachments.length" class="attachment">
          <div
            v-for="(attachment,index) in comment.attachments"
            :key="index"
            @click="$download(attachment)"
          >
            <attachment-type :name="attachment.file.origin_filename" />
            <span class="attachments_name">&nbsp;{{ attachment.file.origin_filename }}</span>
          </div>
        </div>
        <div class="comment-foot">
          <span>{{ time | time2tips }}</span>
          <div class="comment-foot-right">
            <!-- <span @click.prevent="handleComment">
              <img
                :src="require('@/images/commment@2x.png')"
                class="m-style-svg m-svg-def"
              />
              <font v-if="commentCount > 0">{{ commentCount | formatNum }}</font>
            </span> -->

            <span @click.prevent="handleLike">
              <img
                v-if="liked"
                :src="require('@/images/praise_on@2x.png')"
                class="m-style-svg m-svg-def"
              >
              <img
                v-else
                :src="require('@/images/praise@2x.png')"
                class="m-style-svg m-svg-def"
              >
              <font v-if="likeCount > 0" :class="{ liked }">{{ likeCount | formatNum }}</font>
            </span>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import markdownIt from 'markdown-it'
  import plusImagePlugin from 'markdown-it-plus-image'
  import * as api from '@/api/group'

  /**
   * 字符串长度计算(仅获取中文字符字数)
   * @author jsonleex <jsonlseex@163.com>
   * 按 4个英文字符 = 1个中文字符 计算
   */
  function strLength (str) {
    let totalLength = 0
    let i = 0
    let charCode
    for (; i < str.length; i++) {
      charCode = str.charCodeAt(i)
      if (charCode < 0x007f) {
        totalLength = totalLength + 0.25
      } else if (charCode >= 0x0080 && charCode <= 0x07ff) {
        totalLength += 1
      } else if (charCode >= 0x0800 && charCode <= 0xffff) {
        totalLength += 1.5
      }
    }
    return totalLength
  }

  export default {
    name: 'ArticleCommentItemChild',
    components: {
    },
    props: {
      comment: { type: Object, required: true },
      pinned: { type: Boolean, default: false },
      first: { type: Boolean, default: false },
    },
    data () {
      return {
        showAll: false,
      }
    },
    computed: {
      is_anonymous () {
        return this.comment.anonymous === 1
      },
      // 需求变更：不显示更多按钮
      // isShowAll: {
      //   get() {
      //     return this.bodyLength < 60 || this.showAll;
      //   },
      //   set(val) {
      //     this.showAll = val;
      //   }
      // },
      userName () {
        const name = this.$getUserName(this.user, this.comment.anonymous == 1, this.comment.user.name)
        // console.log(this.user, this.comment.anonymous,this.comment.username,name)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.comment.anonymous_name = name
        return name
      },
      anonymous () {
        return this.comment.anonymous == 1
      },
      isShowAll () {
        return true
      },
      user () {
        const { user } = this.comment
        return user && user.id ? user : { id: this.comment.user_id }
      },
      replyUser () {
        const { reply } = this.comment
        return reply && reply.id ? reply : null
      },
      replyAnonymous () {
        return this.comment.reply_anonymous == 1
      },
      body () {
        return this.comment.body || ''
      },
      bodyLength () {
        return strLength(this.body)
      },
      time () {
        return this.comment.created_at || ''
      },
      liked: {
        get () {
          return !!this.comment.liked
        },
        set (val) {
          this.comment.liked = val
        },
      },
      likeCount: {
        get () {
          return this.comment.likes_count || 0
        },
        set (val) {
          this.comment.likes_count = ~~val
        },
      },
      commentCount: {
        get () {
          return this.comment.comments_count || 0
        },
        set (val) {
          this.comment.comments_count = val
        },
      },
    },
    mounted () {
      this.$store.commit('SAVE_USER', this.user)
    },
    methods: {
      formatBody (body) {
        const emotionReg = new RegExp(/\/assets\/pc\/ueditor\/dialogs\/emotion\/images\//)

        body = body.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match, capture) {
          if (!emotionReg.test(capture)) {
            return `<img src="${capture}" onClick="showBig('${capture}')"/>`
            // return `<img src="${capture}" onClick=" let src = this.src; let app = document.getElementById('app'); let imgBox = document.createElement('div'); imgBox.className = 'img-box'; app.appendChild(imgBox); imgBox.innerHTML = '<div><img src=\'+src+\'></div>'; imgBox.addEventListener('click',function(iv){imgBox.remove()})"/>`
          } else {
            return match
          }
        })

        return body
      },
      handelClick () {
        if (!this.isShowAll) return (this.isShowAll = !this.isShowAll)
        this.$emit('click', this.comment)
      },
      handleComment () {
        this.$emit('replyShow', this.comment.user, this.comment)
      },
      handleLike () {
        api.likePostComment(this.comment.id, this.liked).then(() => {
          if (this.comment.liked) {
            this.likeCount -= 1
          } else {
            this.likeCount += 1
          }
          this.liked = !this.liked
        })
      },
      viewUser (id) {
        const userId = this.replyUser.id
        this.$router.push({ name: 'UserDetail', params: { userId } })
      },
    },
  }
</script>

<style lang="less" scoped>
.m-art-comment {
  user-select: auto;
  padding: 24px 0;
}
.attachment {
  color: #63769A
}
.attachment > .img img{
  width: 18px;
}
.attachments_name {
  font-size: 22px;
  max-width: 500px;
  word-break: break-all;
}

.m-art-comment-usr {
  h4 {
    font-size: 24px;

    .sign {
      width: 25px;
      height: 25px;
      margin-left: 10px;
    }
    .wonderful {
      width: 56px;
      margin-left: 10px;
    }
  }

  .m-aln-center {
    width: 60px;
    height: 40px;
    display: block;
    position: relative;
    .m-more {
      width: 20px;
      position: absolute;
      right: 0;
      top: 49%;
    }
  }
}
/deep/ .m-art-comment-con {
  font-size: 28px;
  color: #333;

  .m-text-box {
    display: inline-block;

    img {
      max-width: 100%;
      max-height: 400px;
      object-fit: cover;
    }
  }

  .m-art-comment-rep {
    > a {
      color: #5186EB;
    }
  }
}
.comment-foot {
  font-size: 24px;
  color: #B2B2B2;
  margin-top: 20px;
  display: flex;

  .comment-foot-right {
    margin-left: auto;
    position: relative;
    width: 70px;
    color: #1A1A1A;

    > span {
      position: absolute;
      left: 0;
      display: flex;

      > img {
        width: 28px;
        height: 28px;
      }

      > font {
        vertical-align: top;
        margin-top: -20px;
        margin-left: 10px;
      }

      > font.liked {
        color: @primary;
      }
    }
  }
}
</style>
