<template>
  <Transition name="toast">
    <div
      v-if="show"
      class="m-box-model m-pos-f"
      style="background-color: #f4f5f6; z-index: 101"
      @touchmove.prevent
    >
      <CommonHeader :back="cancel" />

      <ul class="list">
        <li
          v-for="group in list"
          :key="group.id"
          @click.capture.stop="selectGroup(group)"
        >
          <GroupItem :group="group" :choose="choose.id == group.id" />
        </li>
      </ul>

      <div
        class="plr20 m-lim-width"
        :class="{ disabled }"
        style="margin-top: 0.6rem;"
      >
        <button class="m-long-btn m-signin-btn" @click="movePost">
          <span>确认</span>
        </button>
      </div>
    </div>
  </Transition>
</template>

<script>
  import { noop } from '@/util'
  import GroupItem from '@/page/group/components/GroupItemNew.vue'

  export default {
    name: 'MovePost',
    components: { GroupItem },
    data () {
      return {
        show: false,
        disabled: true,
        list: [],
        loading: false,
        applyApi: noop, // 移贴的api 类型是一个 Promise 对象
        applyCallback: noop,
        choose: {
          id: 0,
        },
      }
    },
    watch: {
      $route (to, from) {
        if (to !== from) this.cancel()
      },
    },
    created () {
      /**
       * 弹出移贴窗口 (hooks -> MovePost)
       * @author mutoe <mutoe@foxmail.com>
       * @param {Object} options
       * @param {AxiosPromise} options.api 申请置顶的 api，需要返回 axios promise 对象
       * @param {string|Object} options.payload 申请置顶 api 的第一个参数，取决于 api 中的设定
       * @param {requestCallback} [options.callback = noop] 申请置顶成功后执行的回调方法
       */
      this.$bus.$on('MovePost', options => {
        const { api, payload, callback = noop } = options
        this.applyApi = api
        this.applyPayload = payload
        this.applyCallback = callback || noop
        this.open()
      })
      this.fetchList()
    },
    methods: {
      async fetchList () {
        const list = await this.$store.dispatch('group/getMyGroups')
        this.list = list
      },
      movePost () {
        if (this.loading && !this.disabled) return
        this.loading = true
        const groupId = this.choose.id

        this.applyApi(this.applyPayload, groupId)
          .then(({ data = {} }) => {
            this.loading = false
            this.$Message.success(data)
            this.applyCallback()
            this.$nextTick(this.cancel)
          })
          .catch(err => {
            this.loading = false
            this.$Message.error(err.response.data)
          })
      },
      async open () {
        this.show = true
      },
      cancel () {
        this.show = false
        this.applyApi = noop
        this.applyPayload = {}
        this.applyCallback = noop
      },
      selectGroup (group) {
        this.choose = group
        this.disabled = false
      },
    },
  }
</script>

<style lang="less" scoped>
.p-choose-group {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  background-color: #f4f5f5;

  .list {
    > li + li {
      border-top: 1px solid @border-color; /* no */
    }
  }

  .no-groups {
    padding-top: 180px;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;

    > button {
      background-color: @primary;
      color: #fff;
      border-radius: 8px;
      padding: 15px 120px;
      font-size: 32px;
      margin-top: 30px;
    }
  }
}

.disabled {
  color: #ccc;
}
/deep/ .c-group-item {
  border-bottom: 1px solid #E5E5E5;
}
</style>
