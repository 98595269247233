<template>
  <Transition name="toast">
    <div v-if="show" class="c-popup-dialog">
      <div class="panel">
        <main v-html="content" />
        <footer>
          <span @click="close" v-text="cancelText" />
          <span @click="confirm" v-text="confirmText" />
        </footer>
      </div>
    </div>
  </Transition>
</template>

<script>
  import { noop } from '@/util'

  export default {
    name: 'PopupConfirm',
    data () {
      return {
        show: false,

        content: '',
        onClose: noop,
        onConfirm: noop,
        confirmText: '确认',
        cancelText: '取消',
      }
    },
    created () {
      this.$bus.$on('popupConfirm', (content, options = {}) => {
        if (typeof content === 'object') options = content
        else options.content = content
        Object.assign(this.$data, options)
        this.show = true
      })
    },
    methods: {
      confirm () {
        this.show = false
        this.onConfirm()
      },
      close () {
        this.show = false
        this.onClose()
      },
    },
  }
</script>

<style lang="less" scoped>
.c-popup-dialog {
  display: flex;
  align-items: center;
  position: fixed;
  top: -100px;
  height: calc(~"100% + 200px");
  left: -100px;
  right: -100px;
  padding: 100px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;

  .panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 80%;
    margin: 0 auto;
    border-radius: 10px;
    max-height: 70%;
  }

  header {
    flex: none;
    padding: 38px 0;
  }

  main {
    flex: auto;
    overflow: auto;
    border-width: 1px 0; /* no */
    padding: 80px 50px;
    width: 100%;
    border-bottom: 1px solid #ededed;
    text-align: center;
    font-size: 30px;
    line-height: 60px;
  }

  footer {
    flex: none;
    color: @primary;
    text-align: center;
    width: 100%;
    display: flex;

    > span {
      display: block;
      width: 100%;
      padding: 30px 0;
    }

    > span:first-child {
      border-right: 1px solid #ededed;
    }
  }
}
</style>
