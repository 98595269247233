<template>
  <footer class="foot-guide">
    <section
      :class="{ active: isCurPath('/home') }"
      class="guide-item"
      @click="to('/home')"
    >
      <img
        :src="require(`@/images/ico_tabar_home${isCurPath('/home') ? '_on' : ''}@2x.png`)"
        class="m-style-svg m-svg-def"
      >
      <span>首页</span>
    </section>

    <section
      :class="{ active: isCurPath('/community') }"
      class="guide-item"
      @click="to('/community')"
    >
      <img
        :src="require(`@/images/ico_tabar_community${isCurPath('/community') ? '_on' : ''}@2x.png`)"
        class="m-style-svg m-svg-def"
      >
      <span>{{ $t("community") }}</span>
    </section>
    <section class="guide-item plus" @click="post">
      <img
        :src="require('@/images/post@2x.png')"
        class="submit"
      >
    </section>
    <section
      :class="{ active: isCurPath('/message') }"
      class="guide-item"
      @click="to({ name: 'MessageHome' })"
    >
      <BadgeIcon :count="unCount">
        <img
          :src="require(`@/images/ico_tabar_messaga${isCurPath('/message') ? '_on' : ''}@2x.png`)"
          class="m-style-svg m-svg-def"
        >
      </BadgeIcon>
      <span>{{ $t("message.name") }}</span>
    </section>
    <section
      :class="{ active: isCurPath('profile') }"
      class="guide-item"
      @click="to('/profile')"
    >
      <BadgeIcon :dot="profile">
        <img
          :src="require(`@/images/ico_tabar_me${isCurPath('/profile') ? '_on' : ''}@2x.png`)"
          class="m-style-svg m-svg-def"
        >
      </BadgeIcon>
      <span>{{ $t("profile.name") }}</span>
    </section>
  </footer>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'FootGuide',
    data () {
      return {
        // has_fans: false,
        unCount: 0,
      }
    },
    computed: {
      ...mapState('message', {
        profile: state => state.user.following + state.user.mutual > 0,
      }),
      ...mapState(['CURRENTUSER']),
      ...mapGetters('message', {
        notification: 'unreadMessage',
      }),
      routeName () {
        return this.$router.name
      },
      publish () {
        return this.CURRENTUSER.permission.publish_video || false
      },
    },
    watch: {
      notification (val) {
        this.unCount = val
      },
    },
    activated () {
      this.unCount = this.notification
    },
    mounted () {
      // 路由不开启缓存是不能正常显示消息数量
      this.unCount = this.notification

      this.$el.parentNode.style.paddingBottom = '1rem'
      var _this = this

      window.Echo && window.Echo.private(`${this.$store.state.CURRENTUSER.id}.chat`)
        .listen('.SendChatMessage', (e) => {
          if (_this.routeName !== 'ChatRoom') {
            _this.unCount = _this.unCount + 1
          }
        })
    },
    methods: {
      to (path) {
        this.$router.push(path)
      },
      isCurPath (path) {
        return this.$route.fullPath.indexOf(path) > -1
      },
      showPostMenu () {
        this.$bus.$emit('post-menu')
      },
      post () {
        if (!this.publish) {
          return this.to('/groups/create_post')
        }
        const actions = [];
        actions.push({
          text: '图文',
          method: () => {
            this.to('/groups/create_post')
          },
        })
        actions.push({
          text: '视频',
          method: () => {
            this.to('/groups/create_post?type=video')
          },
        })

        this.$bus.$emit('actionSheet', actions)
      },
    },
  }
</script>

<style lang="less" scoped>
.foot-guide {
  background-color: #ffffff;
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  //padding: 0 10px;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  height: 100px;
  display: flex;
  box-shadow: 0 -0.026667rem 0.053333rem rgba(0, 0, 0, 0.1);
  box-sizing:content-box;
  padding-bottom: constant(safe-area-inset-bottom) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;

  .m-svg-def {
    width: auto;
    height: 34px;
    margin-bottom: 5px;

    &.plus {
      width: 35px;
      height: 65px;
    }
    + .v-badge-dot {
      top: 0;
      box-shadow: 0 0 0 1px #ed3f14; /*no*/
    }
  }

  /deep/ .guide-item {
    flex: 1;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #1a1a1a;
    .v-badge-dot {
      top: 0;
    }
    .v-badge-count {
      width: auto;
      height: 26px;
      line-height: 26px;
      top: -5px;
      left: 20px;
      font-size: 20px;
      padding: 0 9px;
      border-radius: 20px;
      right: unset;
    }
    &.plus {
      color: #fff !important;
      margin: 0 15px;
      .submit {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: @primary;
      }
    }
    &.active {
      color: @primary;

      > svg {
        color: @primary;
      }
    }
    span {
      font-size: 20px;
      color: inherit;
    }
  }
}
</style>
