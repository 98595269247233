import api from './api'
import lstore from '@/plugins/lstore/lstore'

/**
 * api base url
 */
export const baseURL = `${process.env.VUE_APP_API_HOST}/api/${process.env.VUE_APP_API_VERSION}`

/**
 * 统一接口请求数据返回数量限制
 */
export const limit = ~~(lstore.getData('BOOTSTRAPPERS') || {}).limit || 15

/**
 * 上传文件
 * @author mutoe <mutoe@foxmail.com>
 * @export
 * @param {FormData} formData
 * @returns
 */
export function postFile (formData) {
  return api.post('/files', formData, { validateStatus: s => s === 201 })
}

/**
 * 举报评论
 *
 * @author mutoe <mutoe@foxmail.com>
 * @export
 * @param {number} commentId
 * @param {string} reason 举报原因
 * @returns
 */
export function reportComment (commentId, reason) {
  const url = `/report/comments/${commentId}`
  return api.post(url, { reason }, { validateStatus: s => s === 201 })
}

/**
 * 关于我们
 *
 * @author mutoe <mutoe@foxmail.com>
 */
export function getAboutUs () {
  const url = '/aboutus'
  console.log(url)
  return api.get(url, { validateStatus: s => s === 200 })
}

/**
 * 用户协议
 *
 */
export function getUserAgreement () {
  const url = '/site-agreement-view'
  return api.get(url, { params: { type: 'user' } }, { validateStatus: s => s === 200 })
}

/**
 * 隐私协议
 *
 */
export function getPrivacyAgreement () {
  const url = '/site-agreement-view'
  return api.get(url, { params: { type: 'privacy' } }, { validateStatus: s => s === 200 })
}

/**
 * 意见反馈
 *
 * @export
 * @param {string} cnntent 反馈内容
 * @returns
 */
export function feedback (params) {
  const url = '/feedback'
  return api.post(url, params, { validateStatus: s => s === 201 })
}

/**
 * 获取跳转商城信息
 *
 * @author ZsyD
 */
export function getShop (client) {
  const url = `/plus-id/toShop/${client}`
  return api.get(url, { validateStatus: s => s === 200 })
}
