<template>
  <div class="m-box-model m-card c-feed-card">
    <div class="m-box main">
      <section class="m-box-model m-card-main">
        <article class="m-card-body">
          <h2 class="feed-title">
            <span>{{ title?title:'未命名' }}</span>
          </h2>
          <div v-if="body.length > 0" class="m-card-con">
            <p
              class="m-text-box m-text-cut-3 feed-body m-text-pre"
              v-html="replaceURI(body)"
            />
          </div>
          <PostImage
            v-if="images.length > 0"
            :id="postId"
            :pics="images"
          />
          <FeedVideo
            v-if="video.resource"
            :id="postId"
            :video="video"
          />
        </article>
      </section>
    </div>
    <footer
      v-if="showFooter"
      class="m-box-model m-card-foot "
      @click.stop
    >
      <div class="m-box m-aln-center m-card-tools m-lim-width spcbtw">
        <div>{{ time | time2tips }}</div>
        <div class="f-r">
          <span @click="onUpdate">编辑</span>
          <span @click="onDelete">删除</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { escapeHTML } from '@/filters.js'
  import { transTime } from '@/util'
  import * as api from '@/api/group.js'
  import PostImage from '@/page/group/components/PostImage.vue'
  import FeedVideo from '@/components/FeedCard/FeedVideo.vue'
  import CommentItem from '@/components/FeedCard/CommentItem.vue'

  export default {
    name: 'DraftCard',
    components: {
      PostImage,
      CommentItem,
      FeedVideo,
    },
    props: {
      timeLine: { type: Boolean, default: false },
      draft: { type: Object, required: true },
      showFooter: { type: Boolean, default: true },
      needUpdate: { type: Boolean, default: false },
    },
    computed: {
      ...mapState(['CURRENTUSER']),
      postId () {
        return this.draft.id
      },
      time () {
        const str = this.draft.created_at
        return transTime(str)
      },
      images () {
        if (this.draft.images) {
          const pics = this.draft.images.map((item) => {
            item.file = item.id

            return item
          })
          return pics
        }
        return []
      },
      video () {
        return this.draft.video || false
      },
      body () {
        return this.draft.summary || ''
      },
      title () {
        return this.draft.title || ''
      },
      type () {
        return this.draft.file_type === 1 ? 'image' : 'video'
      },
    },
    mounted () {
      this.user && this.$store.commit('SAVE_USER', this.user)
    },
    methods: {
      replaceURI (str) {
        // XSS filter
        str = escapeHTML(str)

        const reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g
        const linkText = this.$t('article.link_text')
        return str
          ? str.replace(
            reg,
            link =>
              `<a class="m-art-links" href="${link}" onclick='event.stopPropagation()' target="__blank">#${linkText}#</a>`,
          )
          : ''
      },
      onUpdate () {
        if (this.draft.post_from == 1) {
          this.$bus.$emit('popupConfirm', {
            content: '该草稿已在PC端保存，如在移动端编缉会丢失已保存的格式和附件！',
            onClose: () => {

            },
            onConfirm: () => {
              this.$router.push(`/groups/create_post?draft=${this.draft.id}&type=${this.type}`)
            },
            confirmText: '编辑',
            cancelText: '取消',
          })
        } else {
          this.$router.push(`/groups/create_post?draft=${this.draft.id}&type=${this.type}`)
        }
      },
      onDelete () {
        api.DeleteGroupDraftPost(this.draft.id).then(() => {
          this.$Message.success('删除成功')
          this.$emit('unDelete', this.draft.id)
        })
      },
    },
  }
</script>

<style lang="less" scoped>
.top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: @primary;
  font-size: 26px;
  img {
    width: 34px;
    height: 34px;
    margin: 0 10px;
  }
}
.c-feed-card {
  padding: 30px 20px 0;
  box-sizing: border-box;
  background-color: #fff;

  .main {
    padding-bottom: 20px;
  }

  .timeline-text {
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 60px;
    font-size: 44px;

    .month {
      font-size: 24px;
      letter-spacing: 1px; /* no */
    }
  }

  .topics {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 0;
    position: relative;

    .topic-item {
      padding: 6px 16px;
      border-radius: 6px;
      background-color: #F7F7F7;
      font-size: 24px;
      color: #666666;
      margin-right: 12px;
      margin-bottom: 12px;
      cursor: pointer;
    }
    .anonymous-tag {
      padding: 0 15px;
      height: 36px;
      line-height: 36px;
      border: 1px solid #E5E5E5;
      opacity: 1;
      border-radius: 22px;
      color: #999999;
      font-size: 20px;
      position: absolute;
      right: 0;
    }
  }
}

.m-card {

  &-main {
    width: 100%;
  }

  &-con {
    overflow: hidden;
    font-size: 30px;
    line-height: 42px;
    color: @text-color2;
    display: -webkit-box;
    margin-bottom: 20px;

    .needPay:after {
      content: " 付费节点，购买后方可查看原文详情 付费节点，购买后方可查看原文详情 付费节点，购买后方可查看原文详情";
      text-shadow: 0 0 10px @text-color2; /* no */
      color: rgba(255, 255, 255, 0);
      margin-left: 5px;
      // filter: DXImageTransform.Microsoft.Blur(pixelradius=2);
      zoom: 1;
    }
  }

  &-body {
    > h2 {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .feed-body {
      width: 100%;
      font-size: 26px;
      color: #333333;
    }
  }

  &-foot {
    .m-svg-def {
      width: auto;
      height: 27px;
    }
    .liked {
      color: @error;
    }
  }

  &-tools {
    padding: 20px 0;
    color: #b3b3b3;
    font-size: 24px;

    a {
      color: inherit;

      // + a {
      //   margin-left: 60px;
      // }
    }

    span {
      margin-left: 10px;
    }
  }

  &-comments {
    margin-bottom: 30px;
    line-height: 42px;
    color: @text-color3;
    font-size: 26px;
  }
}
.feed-title {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.spcbtw {
  display: flex;

  > div.f-r {
    margin-left: auto;

    > span {
      padding: 5px 25px;
      border: 1px solid rgba(229,229,229,1);
      border-radius: 22px;
    }
    > span:first-child {
      margin-right: 30px;
    }
  }

}
</style>
