import { render, staticRenderFns } from "./ArticleCommentItemChild.vue?vue&type=template&id=6b435594&scoped=true&"
import script from "./ArticleCommentItemChild.vue?vue&type=script&lang=js&"
export * from "./ArticleCommentItemChild.vue?vue&type=script&lang=js&"
import style0 from "./ArticleCommentItemChild.vue?vue&type=style&index=0&id=6b435594&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b435594",
  null
  
)

export default component.exports