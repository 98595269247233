import { version } from '../package.json'
import Vue from 'vue'
import { mapGetters } from 'vuex'

import 'github-markdown-css'
import './style/tsplus.less'
import './icons/iconfont.js' // from http://www.iconfont.cn h5 仓库
import './util/rem'
import './util/prototype' // 原型拓展
import Message from './plugins/message/'
import AsyncImage from './components/FeedCard/v-async-image.js'

import imgCropper from '@/plugins/imgCropper'
import lstore from '@/plugins/lstore/index.js'

import Ajax from './api/api.js'
import mixin from './mixin.js'
import * as filters from './filters.js'
import components from './components.js'

import store from './stores/'
import router from './routers/'
import i18n from './i18n'
import App from './app'
import bus from './bus'
import './registerServiceWorker'
import './vendor'
import mandMobile from 'mand-mobile'
import 'mand-mobile/lib/mand-mobile.css'
Vue.use(mandMobile)
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)

// import * as WebIM from '@/vendor/easemob'
window.io = require('socket.io-client')

export { version }

Vue.mixin(mixin)

components.forEach(component => {
  Vue.component(component.name, component)
})

Vue.config.productionTip = false

Vue.prototype.$http = Ajax
Vue.prototype.$Message = Message
Vue.prototype.$MessageBundle = filters.plusMessageFirst
Vue.prototype.$bus = bus

// 获取匿名称呼
export function getAnonymousNames () {
  const BOOTSTRAPPERS = lstore.getData('BOOTSTRAPPERS')
  if (BOOTSTRAPPERS && BOOTSTRAPPERS.site && BOOTSTRAPPERS.site.anonymous_names) {
    const anonymousNames = BOOTSTRAPPERS.site.anonymous_names.split(',')
    return anonymousNames[Math.floor((Math.random()) * anonymousNames.length)] || '匿名'
  }
  return '匿名用户'
}

// 获取用户名
Vue.prototype.$getUserName = function (user, anonymity, username) {
  if (store && store.state && store.state.CURRENTUSER && (store.state.CURRENTUSER.id === (user && user.id))) {
    // 是当前用户
    if (!anonymity) {
      // if (user.is_vip && user.vip_info) {
      //   return user.vip_info.true_name ?? user.name
      // }
      // return store.state.CURRENTUSER.name
      if (username) {
        return username
      }
      if (!user) {
        return ''
      }
      if (user.is_vip && user.vip_info) {
        return user.vip_info.true_name ?? user.name
      }
      return user.name
    } else {
      return `${store.state.CURRENTUSER.name}(匿名)`
      // return `${username}(匿名)`
    }
  } else {
    if (anonymity) {
      return getAnonymousNames()
    } else {
      if (username) {
        return username
      }
      if (!user) {
        return ''
      }
      if (user.is_vip && user.vip_info) {
        return user.vip_info.true_name ?? user.name
      }
      return user.name
    }
  }
}
// 获取用户名-新的-动态列表
Vue.prototype.$getUserNameNew = function (user, anonymity, post) {
  if (!post.user) return
  if (store && store.state && store.state.CURRENTUSER && (store.state.CURRENTUSER.id === (user && user.id))) {
    // 是当前用户
    if (!anonymity) {
      return post.user.name
    } else {
      return `${post.user.name}(匿名)`
    }
  } else {
    if (anonymity) {
      return getAnonymousNames()
    } else {
      return post.user.name
    }
  }
}
// 获取用户名-新的-评论列表
Vue.prototype.$getUserNameNewComment = function (user, anonymity, comment) {
  if (store && store.state && store.state.CURRENTUSER && (store.state.CURRENTUSER.id === (user && user.id))) {
    // 是当前用户
    if (!anonymity) {
      return comment.user.name
    } else {
      return `${comment.user.name}(匿名)`
    }
  } else {
    if (anonymity) {
      return getAnonymousNames()
    } else {
      return comment.user.name
    }
  }
}

Vue.prototype.$getUrl = function () {
  return window.location.origin + process.env.BASE_URL.substr(0, process.env.BASE_URL.length - 1) + '/#' +this.$route.fullPath
},

Vue.prototype.getQueryVariable = function (variable, url) {
  if (!url) {
    url = window.location.href
  }
  const query = url.indexOf('?') > 0 ? url.split('?')[1] : ''

  const vars = query.split('&')

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] === variable) {
      console.log(vars,pair);
      return pair[1]
    }
  }

  return false
}

Vue.use(AsyncImage)
Vue.use(imgCropper)
Vue.use(lstore)

for (const k in filters) {
  Vue.filter(k, filters[k])
}
if (!window.initUrl) {
  window.initUrl = window.location.href.replace(/(\/$)/, '')
}

/* eslint-disable no-new */
new Vue({
  store,
  router,
  i18n,
  created () {},
  render: h => h(App),
  ...mapGetters('message', {
    notification: 'unreadMessage',
  }),
  beforeMount () {
    if (typeof this.$store.state.CURRENTUSER.id !== 'undefined') {
      return
    }
    const _this = this
    let logintype = _this.getQueryVariable('logintype', window.initUrl)
    let code = _this.getQueryVariable('code', window.initUrl)
    if (process.env.VUE_APP_ROUTER_MODE === 'hash' && code) {
      code = code.split('#')[0]
    }

    if (process.env.VUE_APP_ROUTER_MODE === 'hash' && logintype) {
      logintype = logintype.split('#')[0]
    }

    // 企业微信免登

    if (code) {
      // 有code进行免登 这属于登录过的  直接登录
      console.log('有code进行免登 这属于登录过的，直接登录')
      _this.$http
        .post('/work-wechat/login', { code }, { validateStatus: s => s >= 0 })
        .then((response) => {
          console.log(response)
          // const { data: { message, access_token: token } = {}, status } = response
          // console.log(response)
          const status = response.status
          const message = response.data.message||''
          const token = response.data.access_token
          switch (status) {
            case 200:
              lstore.setData('H5_ACCESS_TOKEN', `Bearer ${token}`)
              store.dispatch('fetchUserInfo')
              this.$bus.$emit('loginRefresh')
              router.replace('/home')
              return true
            default:
              _this.$Message.noticeMessage(message)
              return false
          }
        })
        .catch(() => false)
    } else {
      // 当传为id的时候  需要密码账号登录
      if (logintype === 'id') {
        window.location.href = window.initUrl
      } else {
        // 当前没有id也没有code的情况下  面密码登录
        window.location.href=`${process.env.VUE_APP_API_HOST}/api/${process.env.VUE_APP_API_VERSION}/work-wechat/redirect`
      }
    }
  },
  destroyed() {
    clearInterval(this.timer)
  },
  mounted () {
    this.timer =  window.setInterval(() => {
      if (this.$store.state.loginStatus) {
        store.dispatch("message/getAllUnreadCount")
      }
    }, 20000)
  },
}).$mount('#app')

// Exposed versions
/* eslint-disable no-console */
// console.info(
//   `%cWelcome to Plus(ThinkSNS+)! Release %c v${version} `,
//   'color: #00A9E0;',
//   'background:#35495e; padding: 1px; border-radius: 3px; color: #fff;',
// )
// console.info(
//   `%cDevelopment by SlimKit Group 👉 https://github.com/slimkit \nApache-2.0 Licensed | Copyright © ${new Date().getFullYear()} Chengdu ZhiYiChuangXiang Technology Co., Ltd. All rights reserved.`,
//   'color: #00A9E0;',
// )
