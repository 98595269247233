<template>
  <div class="c-banner-advertisement">
    <Swipe
      v-if="list.length"
      :autoplay-time="loopTime"
      class="banner-swipe"
    >
      <SwipeItem v-for="ad in list" :key="ad.id">
        <div class="wrap" @click="to(ad.data.link)">
          <div :style="{ backgroundImage: `url(${ad.data.image})` }" class="ad">
            <!-- {{ ad.title }} -->
            <!-- <div class="title">
              <h2>旭辉之声</h2>
              <h3>用心构筑美好生活</h3>
            </div> -->
          </div>
        </div>
      </SwipeItem>
    </Swipe>
  </div>
</template>

<script>
  import 'c-swipe/dist/swipe.css'
  import { Swipe, SwipeItem } from 'c-swipe'
  import * as bootApi from '@/api/bootstrappers.js'

  /**
   * 广告位类型映射表
   */
  const adMap = {
    'group:single': 'group:single',
  }

  export default {
    name: 'BannerAd',
    components: { Swipe, SwipeItem },
    props: {
      /**
       * 广告类型
       * 可选值: feed:hot (热门动态列表页), news (资讯列表页),
       * @type {string}
       */
      type: {
        type: String,
        required: true,
        validator: val => Object.keys(adMap).includes(val),
      },
      /**
       * 轮播图轮询时间 ms
       * @type {number}
       */
      loopTime: { type: Number, default: 3000 },
      /** 广告张数 */
      size: { type: String, value: '0' },
    },
    data () {
      return {
        list: [], // 轮播图列表
      }
    },
    computed: {
      /**
       * 顶部 banner 广告列表
       * @returns {number}
       */
      adTypeId () {
        const adType = this.$store.getters.getAdTypeBySpace(adMap[this.type])
        return adType.id
      },
    },
    mounted () {
      this.fetchList()
    },
    methods: {
      fetchList () {
        // TODO: @mutoe [api] refactor there with vuex action
        bootApi.getAdsById(this.adTypeId).then(({ data }) => {
          this.list = data.sort((a, b) => a.sort < b.sort)
          if (this.size !== '0') {
            this.list = this.list.slice(0, 1)
          }
        })
      },
      to (url) {
        location.href = url
      },
    },
  }
</script>

<style lang="less" scoped>
.c-banner-advertisement {
  .banner-swipe {
    .wrap {
      @radio: 414 / 215; // banner 固定的宽高比

      height: calc(~"100vw / @{radio}"); // 由屏幕宽度计算出相应比例的高度
      position: relative;
      display: block;

      .title {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 30px;
        h2 {
          font-size: 42px;
          font-weight: 600;
        }
      }
      .subtitle {
      }

      .ad {
        background: no-repeat center / cover;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
