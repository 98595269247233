<template>
  <section class="c-group-item" @click="beforeViewDetail">
    <h2 class="m-text-cut">{{ group.name }}</h2>
    <svg v-if="choose" class="m-style-svg m-svg-def"><use xlink:href="#icon-qs_focused" /></svg>
  </section>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'GroupItemNew',
    props: {
      group: { type: Object, required: true },
      showAction: { type: Boolean, default: true },
      showRole: { type: Boolean, default: true },
      choose: { type: Boolean, default: false },
    },
    data () {
      return {
        loading: false,
      }
    },
    computed: {
      ...mapState(['CURRENTUSER']),
      avatar () {
        const avatar = this.group.avatar || {}
        return avatar.url || null
      },
      name () {
        return this.group.name
      },
      feedCount () {
        return this.group.posts_count || 0
      },
      memberCount () {
        return this.group.users_count || 0
      },
      mode () {
        return this.group.mode
      },
      money () {
        return this.group.money || 0
      },
      joined () {
        return this.group.joined || false
      },
      role () {
        return typeof this.group.joined === 'object' ? this.joined.role : false
      },
      isOwner () {
        return this.role === 'founder'
      },
      isAdmin () {
        return this.role === 'administrator'
      },
      needPaid () {
        return this.mode === 'paid' && this.money > 0
      },
    },
    methods: {
      beforeJoined () {
        if (this.joined || this.loading) return
        this.loading = true
        !this.needPaid
          ? this.joinGroup()
          : this.$bus.$emit('payfor', {
            title: this.$t('group.pay.apply'),
            confirmText: this.$t('group.pay.join'),
            amount: this.money,
            content: this.$t('group.pay.confirm', [this.money]),
            checkPassword: true,
            onOk: async password => {
              this.loading = false
              if (this.money <= this.CURRENTUSER.currency.sum) { this.joinGroup(password) } else this.$router.push({ name: 'currencyRecharge' })
            },
            onCancel: () => {
              this.loading = false
            },
          })
      },
      joinGroup (password) {
        this.$store
          .dispatch('group/joinGroup', {
            groupId: this.group.id,
            needPaid: this.needPaid,
            password,
          })
          .then(data => {
            this.loading = false
            this.$Message.success(data)
            this.group.joined = this.needPaid ? {} : { audit: 1 }
          })
          .catch(err => {
            this.loading = false
            this.$Message.error(err.response.data)
          })
      },
      beforeViewDetail () {
        this.joined
          ? this.joined.audit === 1
            ? this.$router.push(`/groups/${this.group.id}`)
            : this.$Message.error(this.$t('group.need_review'))
          : this.mode !== 'public'
            ? this.$Message.error(this.$t('group.need_join_first'))
            : this.$router.push(`/groups/${this.group.id}`)
      },
    },
  }
</script>

<style lang="less" scoped>
.c-group-item {
  display: flex;
  align-items: center;
  padding: 30px 20px;
  background-color: #fff;

  h2 {
    font-size: 32px;
    max-width: 11em;
  }

  /deep/ svg.m-svg-def {
    color: #6085CC;
    width: 20px;
    margin-left: auto;
  }
}
</style>
