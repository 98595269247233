<template>
  <div class="p-profile-collection">
    <CommonHeader />
    <main>
      <JoLoadMore
        ref="loadmore"
        @onRefresh="onRefresh"
        @onLoadMore="onLoadMore"
      >
        <ul class="feed-list">
          <li
            v-for="(card, index) in list"
            :key="`draft-${card.id}-${index}`"
            :data-feed-id="card.id"
          >
            <DraftCard
              v-if="card.user_id"
              :draft="card"
              @unDelete="handleUnDelete"
            />
          </li>
        </ul>
      </JoLoadMore>
    </main>
  </div>
</template>

<script>
  import i18n from '@/i18n'
  import { limit } from '@/api'
  import * as api from '@/api/group'
  import DraftCard from './components/DraftCard'

  export default {
    name: 'ProfileDraft',
    components: { DraftCard },
    data () {
      return {
        list: [],
      }
    },
    created () {
      // this.$matomoTrackPageView(`我的草稿 - 旭辉员工论坛（SPA）`)
    },
    mounted () {
      this.$refs.loadmore.beforeRefresh()
    },
    methods: {
      onRefresh () {
        api.getDraftPostByUser().then(({ data }) => {
          this.list = data
          this.$refs.loadmore.afterRefresh(data.length < limit)
        })
      },
      onLoadMore () {
        const offset = this.list.length
        api.getDraftPostByUser({ offset }).then(({ data }) => {
          this.list = [...this.list, ...data]
          this.$refs.loadmore.afterLoadMore(data.length < limit)
        })
      },
      // 删除草稿时 从列表中移除
      handleUnDelete (id) {
        const { list } = this
        this.$set(this, 'list', list.filter(draft => draft.id !== id))
      },
    },
  }
</script>
<style lang="less">
.feed-list {
  background: #f7f7f7;

  li {
    margin-bottom: 20px;
  }
}
</style>
