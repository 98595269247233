<template>
  <div class="p-profile-news">
    <CommonHeader :pinned="true">{{ $t('profile.news.name') }}</CommonHeader>

    <main>
      <div class="m-sub-nav m-main">
        <RouterLink
          replace
          exact
          tag="div"
          exact-active-class="active"
          to="/profile/news/released"
          class="m-sub-nav-item"
        >
          <a>{{ $t('profile.news.published') }}</a>
        </RouterLink>
        <RouterLink
          replace
          exact
          tag="div"
          exact-active-class="active"
          to="/profile/news/auditing"
          class="m-sub-nav-item"
        >
          <a>{{ $t('profile.news.review') }}</a>
        </RouterLink>
        <RouterLink
          replace
          exact
          tag="div"
          exact-active-class="active"
          to="/profile/news/rejected"
          class="m-sub-nav-item"
        >
          <a>{{ $t('profile.news.reject') }}</a>
        </RouterLink>
      </div>
      <JoLoadMore
        ref="loadmore"
        class="main-container"
        @onRefresh="onRefresh"
        @onLoadMore="onLoadMore"
      >
        <NewsCard
          v-for="news in newsList"
          :key="news.id"
          :news="news"
        />
      </JoLoadMore>
    </main>
  </div>
</template>
<script>
  import { limit } from '@/api'
  import * as api from '@/api/news'
  import NewsCard from '@/page/news/components/NewsCard'

  export default {
    name: 'ProfileNews',
    components: {
      NewsCard,
    },
    data () {
      return {
        released: [],
        auditing: [],
        rejected: [],
      }
    },
    computed: {
      newsList: {
        get () {
          return this.$data[this.type]
        },
        set (val) {
          this.$data[this.type] = val
        },
      },
      type () {
        return this.$route.params.type || 'released'
      },
      typeParam () {
        if (this.type === 'released') {
          return 0
        }
        if (this.type === 'rejected') {
          return 3
        }
        return 1
      },
    },
    watch: {
      type (val) {
        if (this.isCurrentView && val) {
          this.$refs.loadmore.beforeRefresh()
        }
      },
    },
    activated () {
      this.isCurrentView = true
    },
    deactivated () {
      this.isCurrentView = false
    },
    methods: {
      onRefresh () {
        const params = { type: this.typeParam }
        api.getMyNews(params).then(({ data }) => {
          this.newsList = data
          this.$refs.loadmore.afterRefresh(data.length < limit)
        })
      },
      onLoadMore () {
        const last = this.newsList.slice(-1)[0] || {}
        const params = { type: this.typeParam, after: last.id }
        api.getMyNews(params).then(({ data = [] }) => {
          this.newsList.push(...data)
          this.$refs.loadmore.afterLoadMore(data.length < limit)
        })
      },
    },
  }
</script>
<style lang="less">
.p-profile-news {
  .m-sub-nav {
    position: fixed;
    top: 90px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    z-index: 2;
    border-bottom: 1px solid @border-color;/*no*/

    .m-sub-nav-item {
      height: 100%;
      line-height: 90px;
      text-align: center;

      &.router-link-active {
        color: #333;
        border-bottom: 4px solid @primary;
      }
    }
  }

  .main-container {
    margin-top: 90px;
  }
}
</style>
