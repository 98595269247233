<template>
  <div
    ref="article"
    type="post"
    class="p-group-post-detail"
  >
    <CommonHeader>{{ commentCount }}条回复</CommonHeader>

    <main class="m-art content-body">
      <div class="m-art-body ">
        <JoLoadMore
          ref="loadmore"
          :auto-load="false"
          @onRefresh="onRefresh"
          @onLoadMore="onLoadMore"
        >
          <div v-if="show" class="m-lim-width m-art-comment">
            <div class="m-box m-art-comment-wrap m-avatar">
              <Avatar :user="user" :anonymity="anonymous" :is_anonymous="anonymous"/>
              <section class="m-box-model m-flex-grow1 m-flex-shrink1 m-art-comment-body">
                <div @click="replyComment(comment)">
                  <header class="m-box m-aln-center m-justify-bet m-art-comment-usr">
                    <h4 class="m-flex-grow1 m-flex-shrink1">
                      <!-- {{ user.name }} -->
                      {{ userName }}<UserTitle v-if="!anonymous" :user="user" />
                      <img
                        v-if="comment.excellent_at"
                        :src="require('@/images/wonderful@2x.png')"
                        class="wonderful"
                      >
                    </h4>
                    <div class="m-box m-aln-center" @click="handelClick">
                      <img
                        :src="require('@/images/detail_more@2x.png')"
                        class="m-more"
                      >
                    </div>
                  </header>
                  <article :class="{maxh: !isShowAll}" class="m-text-box m-art-comment-con">
                    <template v-if="replyUser">
                      <span class="m-art-comment-rep">
                        回复<RouterLink :to="`/users/${replyUser.id}`">{{ replyUser.name }}</RouterLink>：
                      </span>
                    </template>
                    <p class="m-text-box" v-html="formatBody(body)" />
                    <span
                      v-show="bodyLength > 60 && !isShowAll"
                      class="m-text-more"
                      @click.stop="isShowAll = !isShowAll"
                    >
                      >> 更多
                    </span>
                  </article>
                </div>
                <div v-if="comment.attachments && comment.attachments.length" class="attachment">
                  <div
                    v-for="(attachment,index) in comment.attachments"
                    :key="index"
                    @click="$download(attachment)"
                  >
                    <attachment-type :name="attachment.file.origin_filename" />
                    <span class="attachments_name">&nbsp;{{ attachment.file.origin_filename }}</span>
                  </div>
                </div>
                <div class="comment-foot">
                  <span>{{ time | time2tips }}</span>
                  <div class="comment-foot-right">
                    <span @click="open(comment.user, comment)">
                      <img
                        :src="require('@/images/commment@2x.png')"
                        class="m-style-svg m-svg-def"
                      >
                      <font v-if="commentCount > 0">{{ commentCount | formatNum }}</font>
                    </span>

                    <span @click.prevent="handleLike">
                      <img
                        v-if="liked"
                        :src="require('@/images/praise_on@2x.png')"
                        class="m-style-svg m-svg-def"
                      >
                      <img
                        v-else
                        :src="require('@/images/praise@2x.png')"
                        class="m-style-svg m-svg-def"
                      >
                      <font v-if="likeCount > 0" :class="{ liked }">{{ likeCount | formatNum }}</font>
                    </span>
                  </div>
                </div>

                <div class="comment-c-box">
                  <!-- 评论列表 -->
                  <ArticleCommentItemChild
                    v-for="(item) in comments"
                    :id="`comment-${item.id}`"
                    :key="`comment-${item.id}`"
                    :comment="item"
                    @click="replyComment(item)"
                    @replyShow="replyShow"
                  />
                </div>
              </section>
            </div>
          </div>
        </JoLoadMore>
        <div class="re-foot"><span @click="open(comment.user, comment)">回复评论</span></div>
      </div>
    </main>
  </div>
</template>

<script>
  // import { limit } from '@/api'
  import { mapState } from 'vuex'
  import * as groupApi from '@/api/group'
  import i18n from '@/i18n'
  import markdownIt from 'markdown-it'
  import plusImagePlugin from 'markdown-it-plus-image'
  import ArticleCommentItemChild from '@/page/article/components/ArticleCommentItemChild.vue'
  const typeMap = {
    post: {
      title: '动态',
      getComments: groupApi.getPostComments,
      postComment: groupApi.postGroupPostComment,
      deleteComment: groupApi.deletePostComment,
      excellentComment: groupApi.excellentComment,
      unExcellentComment: groupApi.unExcellentComment,
    },
  }
  const limit = 10000
  function strLength (str) {
    let totalLength = 0
    let i = 0
    let charCode
    for (; i < str.length; i++) {
      charCode = str.charCodeAt(i)
      if (charCode < 0x007f) {
        totalLength = totalLength + 0.25
      } else if (charCode >= 0x0080 && charCode <= 0x07ff) {
        totalLength += 1
      } else if (charCode >= 0x0800 && charCode <= 0xffff) {
        totalLength += 1.5
      }
    }
    return totalLength
  }

  export default {
    components: {
      ArticleCommentItemChild,
    },
    data: () => ({
      comment: {},
      comments: [],
      show: false,
    }),
    computed: {
      ...mapState(['CURRENTUSER']),
      // 需求变更：不显示更多按钮
      // isShowAll: {
      //   get() {
      //     return this.bodyLength < 60 || this.showAll;
      //   },
      //   set(val) {
      //     this.showAll = val;
      //   }
      // },
      count () {
        return Number(this.$route.query.count) || 0
      },
      userName () {
        // console.log('beforename',this.user, this.anonymous, this.comment.username)
        const name = this.$getUserName(this.user, this.anonymous, this.comment.username)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // console.log('name',name)
        this.comment.anonymous_name = name
        return name
      },
      isShowAll () {
        return true
      },
      user () {
        const { user } = this.comment
        return user && user.id ? user : { id: this.comment.user_id }
      },
      replyUser () {
        const { reply } = this.comment
        return reply && reply.id ? reply : null
      },
      body () {
        return this.comment.body || ''
      },
      bodyLength () {
        return strLength(this.body)
      },
      time () {
        return this.comment.created_at || ''
      },
      article () {
        return this.comment.commentable_id || 0
      },
      factory () {
        // const type = this.comment.commentable_type
        const type = 'post'
        return typeMap[type]
      },
      anonymous () {
        return this.comment.anonymous == 1
      },
      liked: {
        get () {
          return !!this.comment.liked
        },
        set (val) {
          this.comment.liked = val
        },
      },
      likeCount: {
        get () {
          return this.comment.likes_count || 0
        },
        set (val) {
          this.comment.likes_count = ~~val
        },
      },
      commentCount: {
        get () {
          return this.comment.comments_count || 0
        },
        set (val) {
          this.comment.comments_count = val
        },
      },
      isManager () {
        return this.CURRENTUSER.permission.is_admin || false
      },
    },
    activated () {
      this.$refs.loadmore.beforeRefresh()
    },
    methods: {
      formatBody (body) {
        const emotionReg = new RegExp(/\/assets\/pc\/ueditor\/dialogs\/emotion\/images\//)

        body = body.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match, capture) {
          if (!emotionReg.test(capture)) {
            return `<img src="${capture}" onClick="showBig('${capture}')"/>`
            // return `<img src="${capture}" onClick=" let ev = window.event || arguments.callee.caller.arguments[0]; ev.stopPropagation();  let src = this.src; let app = document.getElementById('app'); let imgBox = document.createElement('div'); imgBox.className = 'img-box'; app.appendChild(imgBox); imgBox.innerHTML = '<div><img src=\'+src+\'></div>'; imgBox.addEventListener('click',function(iv){imgBox.remove()});"/>`
          } else {
            return match
          }
        })

        return body
      },
      fetchComment () {
        this.$http.get(`/comment/${this.$route.params.commentId}`, { limit, offset: 0 })
          .then(({ data }) => {
            this.comment = data
          })
      },
      replyComment (comment) {
        const actions = []
        // 是否是自己的评论
        if (comment.user_id === this.CURRENTUSER.id || this.isManager) {
          actions.push({
            text: this.$t('reply.name'),
            method: () => this.open(comment.user, comment),
          })
          actions.push({
            text: '删除',
            method: () => this.delete(comment.id),
          })
        } else {
          actions.push({
            text: this.$t('reply.name'),
            method: () => this.open(comment.user, comment),
          })
          actions.push({
            text: this.$t('report.name'),
            method: () => {
              this.$bus.$emit('report', {
                type: 'postComment',
                payload: comment.id,
                username: this.$getUserName(comment.user, comment.anonymous, comment.username),
                reference: comment.body,
              })
            },
          })
        }
        this.$bus.$emit('actionSheet', actions)
      },
      open (replyUser = {}, comments) {
        const comment = comments ? Object.assign({}, comments) : {}
        // 评论id
        const pid = comment.pid ? comment.pid : comment.id
        const id = comment.id
        const isAnonymous = comment.anonymous === 1
        const anonymousName = comment.anonymous_name
        let placeholder
        if (replyUser.name) placeholder = `${this.$t('reply.name')} ${this.$getUserName(replyUser, isAnonymous, comment.username)}：`

        this.$bus.$emit('commentInput', {
          placeholder,
          anonymous: this.anonymous,
          onOk: (body, anonymous) => void this.sendComment(body, (replyUser && replyUser.id) || '', anonymous ? 1 : 2, isAnonymous, anonymousName, pid, id, comments),
        })
      },
      sendComment (body, replyUser, anonymous, isAnonymous, anonymousName, pid, id, base) {
        if (!body) return this.$Message.error(this.$t('comment.empty'))
        const params = { body }
        const bodyEncode = body
        if (replyUser) params.reply_user = replyUser

        this.factory.postComment(this.article, { body: bodyEncode, reply_user: (id == pid ? 0 : replyUser), anonymous: anonymous, pid: pid, ppid: id }).then(async comment => {
          this.$Message.success(this.$t('comment.success'))
          this.$bus.$emit('commentInput:close', true)
          this.$refs.loadmore.beforeRefresh()
          this.goAnchor('#comment-head')
          this.$emit('update:total', 1)
        }).catch(() => {
          this.$bus.$emit('commentInput:close', true)
        })
      },
      delete (commentId) {
        const actions = [
          { text: this.$t('delete.name'), method: () => void this.deleteComment(commentId) },
        ]
        setTimeout(() => {
          this.$bus.$emit('actionSheet', actions, this.$t('cancel'), this.$t('delete.confirm'))
        }, 200)
      },
      deleteComment (commentId) {
        this.factory.deleteComment(this.article, commentId).then(() => {
          this.$emit('deleteComment', commentId)
          this.comments = this.comments.filter(c => c.id !== commentId)
          // this.pinneds = this.pinneds.filter(c => c.id !== commentId)
          this.$emit('update:total', -1)
          this.$Message.success(this.$t('comment.delete.success'))
        })
      },
      handelClick () {
        if (!this.isShowAll) return (this.isShowAll = !this.isShowAll)
        this.$emit('click', this.user.id, this.user.name, this.comment.id)
      },
      handleComment () {
        this.open(replyUser, comment)
        //  this.$refs.comment.open()
      },
      replyShow (replyUser = {}, comments) {
        this.open(replyUser, comments)
      },
      onRefresh () {
        this.$http.get(`/plus-group/group-posts/comments/${this.$route.params.commentId}`, { params: { limit, offset: 0 } })
          .then(({ data }) => {
            this.comment = data
            this.comments = data.children
            this.show = true
            this.$refs.loadmore.afterRefresh(data.children.length < limit)
          }).catch(() => {
            this.$refs.loadmore.afterRefresh()
          })
      },
      onLoadMore () {
        this.$http.get(`/plus-group/group-posts/comments/${this.$route.params.commentId}`, { params: { limit, offset: this.comments.length } })
          .then(({ data }) => {
            this.comment = data
            this.comments = [...this.comments, ...data.children]
            this.$refs.loadmore.afterLoadMore(data.children.length < limit)
          }).catch(() => {
            this.$refs.loadmore.afterLoadmore(true)
          })
      },
    },
  }
</script>
<style lang="less" scoped>
.m-art-comments {
  > .m-art-comment {
    border: 0;
    position: relative;
    padding: 24px 0;
  }
  > .m-art-comment::after {
    content: '';
    position: absolute;
    height: 1px;
    width: calc(100% - 100px);
    background: #ededed;
    right: 0;
    bottom: 0;
  }
}

.m-child-comment {
  user-select: auto;
  border: 0;
  position: relative;
}
.m-child-comment::after {
  content: '';
  position: absolute;
  height: 1px;
  width: calc(100% + 20px);
  background: #ededed;
  left: 0;
  bottom: 0;
}
.m-child-comment:last-child::after {
  width: 0;
  height: 0;
}

.comment-child-count{
  color: #5186EB;
  font-size: 24px;
  margin-top: 20px;
}
.m-avatar {
  &-box-secret {
    width: 56px;
    height: 56px;
  }
}
.m-art-comment-usr {
  // display: block;
  h4 {
    font-size: 24px;

    .sign {
      width: 25px;
      height: 25px;
      margin-left: 10px;
    }
    .wonderful {
      width: 56px;
      margin-left: 10px;
    }
  }

  .m-aln-center {
    height: 30px;
    .m-more {
      width: 20px;
    }
  }
}
/deep/ .m-art-comment-con {
  font-size: 28px;
  color: #333;

  .m-text-box {
    img {
      max-width: 100%;
      max-height: 400px;
      object-fit: cover;
    }
  }

  .m-art-comment-rep {
    > a {
      color: #5186EB;
    }
  }
}
.comment-foot {
  font-size: 24px;
  color: #B2B2B2;
  margin-top: 20px;
  display: flex;

  .comment-foot-right {
    margin-left: auto;
    position: relative;
    width: 180px;
    color: #1A1A1A;

    > span {
      position: absolute;
      left: 0;
      display: flex;

      > img {
        width: 28px;
        height: 28px;
      }

      > font {
        vertical-align: top;
        margin-top: -20px;
        margin-left: 10px;
      }

      > font.liked {
        color: @primary;
      }
    }

    > span:last-child {
      left: 110px;
    }
  }
}

.comment-c-box {
  background: #F7F7F7;
  width: calc(100% + 120px);
  margin-left: -100px;
  padding: 0 20px;
}

.content-body {
  flex: auto;
}
.m-art-comment {
  user-select: auto;
}
.jo-loadmore-main {
  >.m-art-comment {
    padding: 20px 0 0;
  }
}
.re-foot {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 80px;
  text-align: center;
  color: #ccc;
  border-radius: 5px;

  > span {
    background: #efefef;
    border-radius: 50px;
    width: calc(100% - 40px);
    height: 60px;
    line-height: 60px;
    display: inline-block;
    font-size: 26px;
    text-align: left;
    padding: 0 28px;
    margin-top: 10px;
  }
}
/deep/ .m-art-body {
  padding: 20px 0;
  background: #fff;
  margin-bottom: 60px;

  img {
    margin-left: 0;
    width: unset;
  }

  img.sign {
    width: 25px;
    margin-left: 10px;
  }

  .m-art-comment-wrap {
    margin: 0;
    padding: 0 30px;
  }

  .jo-loadmore-foot {
    background: #f7f7f7;
  }
}

</style>
<style>
.m-art-comment-body .m-child-first-comment {
  /* border-top: 1px solid #ededed; */
  position: relative;
  margin-top: 30px;
}
.m-art-comment-body .m-child-first-comment::before {
  content: '';
  position: absolute;
  height: 1px;
  width: calc(100% + 20px);
  background: #ededed;
  left: 0;
  top: 0;
}
.m-art-comment-body .m-child-comment:last-child {
  padding-bottom: 0;
}
</style>
