<template>
  <div class="m-box-model m-card c-feed-card">
    <div class="m-box main">
      <section class="m-box-model m-card-main">
        <div v-if="pinned" class="top">
          <img :src="require('@/images/top@2x.png')" alt="">
          <span>置顶</span>
        </div>
        <header
          v-if="!timeLine && showUser"
          class="m-box m-aln-center m-justify-bet m-card-usr"
        >
          <template v-if="timeLine">
            <div v-if="isToday">{{ $t("date.today") }}</div>
            <div v-else class="timeline-text">
              <span>{{ time.getDate() }}</span>
              <span class="month">{{
                $t(`date.months[${time.getMonth()}]`)
              }}</span>
            </div>
          </template>
          <Avatar
            v-else
            :size="'small'"
            :user="user"
            :anonymity="anonymous"
            :is_anonymous="is_anonymous"
            :old_name="post.username"
            :check="true"
          />
          <!-- 这里绑定头衔 -->
          <h4
            v-if="!anonymous && !is_anonymous"
            class="m-flex-grow1 m-flex-shrink1 marginl10"
          >
            <span
              class="m-box m-aln-center"
              @click="viewUser"
            >{{ userName }}<UserTitle
              :user="user"
            /></span>
          </h4>
          <h4 v-else class="m-flex-grow1 m-flex-shrink1 marginl10">匿名用户</h4>
          <div class="m-box m-aln-center">
            <span
              v-if="collect"
              class="un-collect-btn"
              @click="unCollect"
            >取消收藏</span>
            <span v-else @click="handleView('')">{{ time | time2tips }}</span>
          </div>
        </header>
        <article class="m-card-body" @click="handleView('')">
          <h2 class="feed-title">
            <img
              v-if="post.v_come"
              :src="require('@/images/print_v@2x.png')"
              class="feed-title-sign"
            >
            <img
              v-if="post.hot"
              :src="require('@/images/label_hot@2x.png')"
              class="feed-title-hot"
            >
            <img
              v-if="post.excellent_at"
              :src="require('@/images/label_essence@2x.png')"
              class="feed-title-essence"
            >
            <span :class="{ tag: post.is_activity > 0 }">
              {{ title }}
            </span>
            <img
              v-if="post.lock"
              :src="require('@/images/label_lock@2x.png')"
              class="feed-title-lock"
            >
          </h2>
          <p
            v-if="body"
            class="m-text-box m-card-con"
            :class="{
              'm-text-one': body.length < 27,
              'm-text-pre': body.length >= 29,
              fuzzy: !isPaid,
            }"
            v-html="formatBody(body)"
          />
          <span v-if="body.length >= 29" class="more">查看全文</span>
          <PostFile
            v-if="files.length > 0"
            :id="postId"
            :class="{ fuzzy: !isPaid }"
            :files="files"
          />
          <PostImage
            v-else-if="images.length > 0"
            :id="postId"
            :pics="images"
            :paid="isPaid"
            @pay="pay"
          />
          <FeedVideo
            v-if="video.resource"
            :id="postId"
            :video="video"
            :paid="isPaid"
            @pay="pay"
          />
          <ul class="topics">
            <li class="topic-item" v-text="group.name || ''" />
            <li
              v-if="tag_area"
              class="topic-item"
              v-text="tag_area.name || ''"
            />
            <li
              v-for="tag in tags"
              :key="tag.id"
              class="topic-item"
            >
              <span
                v-if="tagRouter"
                @click.prevent.stop="toTag(tag.id)"
                v-text="tag.name"
              />
              <span v-else v-text="tag.name" />
            </li>
            <span
              v-if="anonymous && !showUser"
              class="anonymous-tag"
            >匿名发布</span>
          </ul>
        </article>
      </section>
    </div>
    <footer
      v-if="showFooter"
      class="m-box-model m-card-foot m-bt1"
      @click.stop
    >
      <div class="m-box m-aln-center m-card-tools m-lim-width spcbtw">
        <a class="m-box m-aln-center flex1" @click.prevent="handleView('')">
          <img
            :src="require('@/images/browse@2x.png')"
            class="m-style-svg m-svg-def"
          >
          <span>{{ viewCount | formatNum }}</span>
        </a>
        <a
          class="m-box m-aln-center flex1"
          @click.prevent="handleView('comment-head')"
        >
          <img
            :src="require('@/images/commment@2x.png')"
            class="m-style-svg m-svg-def"
          >
          <span>{{ commentCount | formatNum }}</span>
        </a>
        <a class="m-box m-aln-center flex1" @click.prevent="handleLike">
          <img
            v-if="liked"
            :src="require('@/images/praise_on@2x.png')"
            class="m-style-svg m-svg-def"
          >
          <img
            v-else
            :src="require('@/images/praise@2x.png')"
            class="m-style-svg m-svg-def"
          >
          <span :class="{ liked }">{{ likeCount | formatNum }}</span>
        </a>
      </div>
      <!--点赞-->
      <div
        v-if="likes.length"
        class="likes"
        :class="commentCount || 'no-comment'"
      >
        <img src="@/images/praise_blue@2x.png" alt="">
        <a
          v-for="(item, index) in likes"
          :key="item.id"
          href="javascript:;"
          @click="viewLikeUser(item.user)"
        >{{ index === 0 ? "" : ", " }}{{ item.user.name }}</a>
      </div>
      <!--评论-->
      <div v-if="commentCount" class="comments">
        <ul class="m-card-comments">
          <li
            v-for="com in comments"
            v-if="com.id"
            :key="com.id"
          >
            <CommentItem :comment="com" @click="commentAction" />
          </li>
        </ul>
        <div
          v-if="commentCount > 5"
          class="m-router-link"
          @click="handleView('comment_list')"
        >
          <a>{{ $t("article.view_all_comments") }}</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { escapeHTML } from '@/filters.js'
  import { transTime } from '@/util'
  import * as api from '@/api/group.js'
  import markdownIt from 'markdown-it'
  import plusImagePlugin from 'markdown-it-plus-image'
  import PostImage from './PostImage.vue'
  import PostFile from './PostFile.vue'
  import FeedVideo from '@/components/FeedCard/FeedVideo.vue'
  import CommentItem from '@/components/FeedCard/CommentItem.vue'

  export default {
    name: 'PostCard',
    components: {
      PostImage,
      PostFile,
      CommentItem,
      FeedVideo,
    },
    props: {
      timeLine: { type: Boolean, default: false },
      pinned: { type: Boolean, default: false },
      post: { type: Object, required: true },
      showFooter: { type: Boolean, default: true },
      currentTopic: { type: Number, default: 0 },
      needUpdate: { type: Boolean, default: false },
      collect: { type: Boolean, default: false },
      tagRouter: { type: Boolean, default: false },
      showUser: { type: Boolean, default: true },
    },
    computed: {
      ...mapState({
        CURRENTUSER: (state) => state.CURRENTUSER,
      }),
      isMine () {
        return this.post.user_id === this.CURRENTUSER.id
      },
      postId () {
        return this.post.id
      },
      group () {
        return this.post.group
      },
      tag_area () {
        return this.post.tag_area || null
      },
      tags () {
        return this.post.tags || []
      },
      likes () {
        return this.post.likes || []
      },
      comments () {
        // get () {
        return this.post.comments ? this.post.comments.slice(0, 5) : []
      // },
      // set (val) {
      //   this.post.comments = val
      // },
      },
      liked: {
        get () {
          return !!this.post.liked
        },
        set (val) {
          this.post.liked = val
        },
      },
      likeCount: {
        get () {
          return this.post.likes_count || 0
        },
        set (val) {
          this.post.likes_count = ~~val
        },
      },
      commentCount: {
        get () {
          return this.post.comments_count || 0
        },
        set (val) {
          this.post.comments_count = val
        },
      },
      viewCount () {
        return this.post.views_count || 0
      },
      time () {
        const str = this.post.dead_line
        return transTime(str)
      },
      isToday () {
        // 时间差 = 当前时间 - date (单位: 秒)
        const offset = (new Date() - this.time) / 1000
        if (offset / 3600 < 24) return true
        return false
      },
      user () {
        const user = this.post.user
        return user && user.id ? user : {}
      },
      needPay () {
        const { paid_node: node } = this.post
        return node && !node.paid
      },
      images () {
        if (this.post.images) {
          const pics = this.post.images.map((item) => {
            item.file = item.id

            return item
          })
          return pics
        }
        return []
      },
      files () {
        if (this.post.file_ids) {
          return this.post.file_ids
        // const files = this.post.file_ids.map((item) => {
        //   item.file = item.id

        //   return item
        // })
        // return pics
        }
        return []
      },
      video () {
        return this.post.video || false
      },
      body () {
        return this.post.summary || this.post.body || ''
      },
      amount () {
        return this.post.amount
      },
      isPaid () {
        return this.post.amount === 0 || this.post.is_paid
      },
      title () {
        return this.post.title || ''
      },
      anonymous () {
        return this.post.anonymous == 1
      },
      // 判断是不是匿名发帖
      is_anonymous () {
        return this.post.is_anonymous === 1
      },
      userName () {
        // const name = this.$getUserName(this.user, this.anonymous)
        const name = this.$getUserNameNew(this.user, this.anonymous, this.post)
        return name
      },
    },
    mounted () {
      this.user && this.$store.commit('SAVE_USER', this.user)
    },
    // watch: {
    //   post () {
    //     console.log(this.post)
    //   }
    // },
    methods: {
      formatBody (body) {
        return markdownIt({
          html: true,
        })
          .use(plusImagePlugin, `${this.$http.defaults.baseURL}/files/`)
          .render(body)
      },
      replaceURI (str) {
        // XSS filter
        str = escapeHTML(str)

        const reg =
          /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g
        const linkText = this.$t('article.link_text')
        return str
          ? str.replace(
            reg,
            (link) =>
              `<a class="m-art-links" href="${link}" onclick='event.stopPropagation()' target="__blank">#${linkText}#</a>`,
          )
          : ''
      },
      handleView (hash) {
        if (this.isPaid) {
          const path = hash
            ? `/groups/${this.group.id}/posts/${this.postId}#${hash}`
            : `/groups/${this.group.id}/posts/${this.postId}`

          this.$router.push({ path: path })
        } else {
          this.pay()
        }
      },
      handleLike () {
        if (!this.isPaid) {
          return this.pay()
        }
        const method = this.liked ? 'delete' : 'post'
        const url = `/plus-group/group-posts/${this.postId}/likes`
        if (this.fetching) return
        this.fetching = true
        this.$http({
          method,
          url,
          validateStatus: (s) => s === 201 || s === 204,
        })
          .then(() => {
            const user = this.CURRENTUSER
            if (method === 'post') {
              this.liked = true
              this.likeCount += 1
              this.post.likes.unshift({ user_id: user.id, user: user })
            } else {
              this.liked = false
              this.likeCount -= 1
              this.post.likes = this.likes.filter(
                (item) => item.user_id !== user.id,
              )
            }
          })
          .finally(() => {
            this.fetching = false
          })
      },
      handleComment ({ comment, placeholder, reply_user }) {
        // 评论id
        const pid = comment.pid ? comment.pid : comment.id
        const id = comment.id

        this.$bus.$emit('commentInput', {
          placeholder,
          onOk: (text, anonymous) => {
            this.sendComment({
              body: text,
              reply_user: id == pid ? 0 : reply_user,
              anonymous: anonymous,
              pid,
              id,
            })
          },
        })
      },
      commentAction ({ isMine = false, placeholder, reply_user: user, comment }) {
        const actions = []
        if (isMine) {
          const isOwner = this.post.user.id === this.CURRENTUSER.id
          actions.push({
            text: this.$t('comment.delete.name'),
            method: () => this.deleteComment(comment.id),
          })
        } else {
          if (this.isPaid) {
            actions.push({
              text: this.$t('reply.name'),
              method: () => {
                this.handleComment({
                  comment,
                  placeholder,
                  reply_user: user,
                })
              },
            })
          }
          actions.push({
            text: this.$t('report.name'),
            method: () => {
              this.$bus.$emit('report', {
                type: 'comment',
                payload: comment.id,
                username: this.$getUserName(
                  comment.user,
                  comment.anonymous == 1,
                  comment.username,
                ),
                reference: comment.body,
              })
            },
          })
        }
        this.$bus.$emit('actionSheet', actions)
      },
      sendComment ({ reply_user: replyUser, body, anonymous, pid, ppid }) {
        if (body && body.length === 0) {
          return this.$Message.error(this.$t('comment.empty'))
        }
        const params = {
          body,
          reply_user: replyUser,
          anonymous: anonymous ? 1 : 2,
          pid,
          ppid,
        }
        api
          .postGroupPostComment(this.postId, params)
          .then((comment) => {
            comment.user = this.$store.state.CURRENTUSER
            const comments = Object.assign([], this.comments)
            comments.unshift(comment)
            if (comments.length > 5) comments.pop()
            this.$Message.success(this.$t('comment.success'))
            this.$bus.$emit('commentInput:close', true)
            this.$emit('updateComments', {
              type: 'add',
              postId: this.post.id,
              comment,
            })
            ++this.commentCount
          })
          .catch(() => {
            this.$bus.$emit('commentInput:close', true)
          })
      },
      deleteComment (commentId) {
        api.deletePostComment(this.postId, commentId).then(() => {
          const comments = Object.assign(
            [],
            this.post.comments.filter((c) => c.id !== commentId),
          )
          --this.commentCount
          this.$Message.success(this.$t('comment.delete.success'))
          if (this.needUpdate) {
            this.$emit('updateComments', {
              postId: this.post.id,
              commentId: commentId,
              type: 'del',
            })
          }
        })
      },
      unCollect () {
        api.uncollectPost(this.post.id).then(() => {
          this.$Message.popupSuccess(this.$t('collect.cancel'))
          this.$emit('unCollect', this.post.id)
        })
      },
      viewUser () {
        const userId = this.user.id
        this.$router.push({ name: 'UserDetail', params: { userId } })
      },
      toTag (tagId) {
        const path = this.$route.path
        this.$router.push(`${path}?type=${tagId}`)
      },
      viewLikeUser (user) {
        const userId = user.id
        this.$router.push({ name: 'UserDetail', params: { userId } })
      },
      pay () {
        this.$bus.$emit('payfor', {
          title: this.$t('group.post.pay.title'),
          amount: this.amount,
          content: this.$t('group.post.pay.content', {
            count: this.amount,
            currencyUnit: this.currencyUnit,
          }),
          confirmText: '立即支付',
          cancelText: '取消',
          onOk: () => {
            api
              .postPaid(this.group.id, this.postId)
              .then(() => {
                this.$Message.success('解锁成功')
                // 更新状态
                this.post.is_paid = true
              })
              .catch((err) => {
                console.log(err.response)
              })
          },
        })
      },
    },
  }
</script>

<style lang="less" scoped>
.top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: @primary;
  font-size: 26px;
  img {
    width: 34px;
    height: 34px;
    margin: 0 10px;
  }
}
.c-feed-card {
  padding: 30px 30px 0;
  box-sizing: border-box;
  background-color: #fff;

  .main {
    padding-bottom: 20px;
  }

  .timeline-text {
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 60px;
    font-size: 44px;

    .month {
      font-size: 24px;
      letter-spacing: 1px; /* no */
    }
  }

  .topics {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 0;
    position: relative;

    .topic-item {
      padding: 6px 16px;
      border-radius: 6px;
      background-color: #f7f7f7;
      font-size: 24px;
      color: #666666;
      margin-right: 12px;
      margin-bottom: 12px;
      cursor: pointer;
    }
    .anonymous-tag {
      padding: 0 15px;
      height: 36px;
      line-height: 36px;
      border: 1px solid #e5e5e5;
      opacity: 1;
      border-radius: 22px;
      color: #999999;
      font-size: 20px;
      position: absolute;
      right: 0;
    }
  }
}

.m-card {
  &-usr {
    font-size: 24px;
    color: #ccc;
    margin-bottom: 30px;

    h4 {
      color: #000;
      font-size: 26px;
    }

    span + span {
      margin-left: 15px;
    }

    .un-collect-btn {
      width: 110px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      border: 1px solid rgba(229, 229, 229, 1);
      border-radius: 3px;
    }
  }

  &-main {
    width: 100%;
  }

  &-con {
    overflow: hidden;
    font-size: 26px;
    line-height: 42px;
    color: #333;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;
    max-height: 126px;
    margin-bottom: 20px;
  }

  .m-text-one {
    -webkit-line-clamp: unset;
  }
  .more {
    height: 0.4rem;
    font-size: 0.28rem;
    font-weight: 600;
    text-align: left;
    color: #576b95;
    line-height: 0.43rem;
    letter-spacing: 0.01rem;
    margin-top: -0.15rem;
    margin-bottom: 0.2rem;
    display: block;
  }
  &-body {
    > h2 {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .feed-body {
      width: 100%;
    }
  }

  &-foot {
    .m-svg-def {
      width: auto;
      height: 27px;
    }
    .liked {
      color: @error;
    }
  }

  &-tools {
    padding: 20px 0;
    color: #1a1a1a;
    font-size: 24px;

    a {
      color: inherit;

      // + a {
      //   margin-left: 60px;
      // }
    }

    span {
      margin-left: 10px;
    }
  }

  &-comments {
    margin-bottom: 30px;
    line-height: 42px;
    color: @text-color3;
    font-size: 26px;
  }
}

.m-router-link {
  height: 40px;
  font-size: 28px;
  font-weight: 600;
  text-align: left;
  color: #576b95;
  line-height: 43px;
  letter-spacing: 1px;
  margin-bottom: 38px;
  a {
    color: inherit;
  }
}
.marginl10 {
  margin-left: 10px;
}
.sign {
  width: 25px;
  height: 25px;
  margin-left: 10px;
}
.feed-title {
  position: relative;
  display: flex;
  align-items: center;
  .tag {
    &::before {
      content: "奖";
      display: inline-block;
      width: 30px;
      height: 30px;
       line-height: 30px;
      font-size: 12px;
      color: #fff;
      background: #e94824;
      border-radius: 4px;
      text-align: center;
      position: relative;
      bottom: 0.06rem;
    }
  }

  &-sign {
    position: absolute;
    right: 0;
    width: 160px;
    height: 160px;
    z-index: 1;
    transform: translateY(15px);
  }
  &-vip {
    right: 130px;
  }
  &-hot {
    width: 24px;
    height: 30px;
    margin-right: 10px;
  }
  &-essence {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  &-lock {
    width: 30px;
    height: 30px;
    margin: 0 0 0 10px;
  }
  > span {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.spcbtw {
  justify-content: space-between;
}
.flex1 {
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-right: 1px solid #e5e5e5;
  &:last-child {
    border: none;
  }
}

.likes {
  width: 690px;
  padding: 30px 27px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background: #f4f4f4;
  border-radius: 12px;
  img {
    margin-right: 12px;
    width: 32px;
    height: 32px;
    line-height: 43px;
  }
  a {
    font-size: 28px;
    color: #576b95;
    line-height: 43px;
    letter-spacing: 1px;
  }
}
.m-card-comments {
  margin-top: 20px;
  margin-bottom: 24px;
  font-size: 28px;
  color: #666666;
  line-height: 43px;
  li {
    margin-bottom: 10px;
  }
}
.no-comment {
  margin-bottom: 33px;
}
.fuzzy {
  filter: blur(15px);
}
</style>
