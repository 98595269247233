<template>
  <div class="p-feed">
    <main class="feed-main">
      <!-- 热门动态顶部 banner 广告位 -->
      <BannerAd type="group:single" size="1" />
      <section class="community">
        <div class="community-box">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="community-box-card"
            @click="gotoDetail(item.id)"
          >
            <img
              :src="getAvatar(item.avatar, item.id)"
              class="logo"
              alt=""
            >
            <div class="content">
              <div class="title">
                {{ item.name
                }}<span>{{ item.posts_count | t("group.post.count") }}</span>
              </div>
              <div class="detail m-text-cut-2">{{ item.summary || '暂无简介' }}</div>
            </div>
            <svg
              class="m-style-svg m-svg-def entry__item--append community-box-card-right"
            >
              <use xlink:href="#icon-arrow-right" />
            </svg>
          </div>
        </div>
      </section>
    </main>
    <FootGuide />
  </div>
</template>

<script>
  import { limit } from '@/api'
  import BannerAd from '@/components/advertisement/BannerAd.vue'

  const feedTypesMap = ['new', 'hot', 'follow']

  export default {
    name: 'Community',
    components: { BannerAd },
    data () {
      return {
        list: [],
      }
    },
    mounted () {
    // this.$matomoTrackPageView('社区 - 旭辉员工论坛（SPA）')
    },
    created () {
      this.fetchList()
    },
    activated () {
      this.fetchList()
    },
    methods: {
      async fetchList () {
        const list = await this.$store.dispatch('group/getGroups')

        this.list = list
      },
      getAvatar (avatar, id) {
        if (avatar && avatar.hasOwnProperty('url')) {
          return avatar.url
        }

        if (id < 5) {
          return require(`@/images/section_${id}@2x.png`)
        }

        return null
      },
      gotoDetail (id) {
        this.$router.push(`/groups/${id}`)
      },
    },
  }
</script>

<style lang="less" scoped>
/deep/ .c-banner-advertisement {
  min-height: 100px;
}
.p-feed {
  //height: 100vh;
  background-color: #fff;
}
.community {
  width: 100%;
  padding: 0 30px;
  background: #fff;

  &-box {
    position: relative;
    top: -60px;
    width: 100%;
    padding: 0 36px;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding-bottom: 40px;

    &-card {
      display: flex;
      align-items: center;
      width: 100%;
      height: 193px;
      padding: 40px 0;
      border-bottom: 1px solid #e5e5e5;

      .logo {
        width: 80px;
        height: 80px;
        margin-right: 24px;
      }
      .content {
        flex: 1;
        .title {
          color: #1a1a1a;
          font-size: 30px;
          font-weight: 600;
          span {
            color: #999999;
            font-size: 20px;
            margin-left: 10px;
          }
        }
        .detail {
          margin-top: 10px;
          color: #666666;
          font-size: 20px;
          line-height: 32px;
        }
      }
      &-right {
        margin-left: auto;
        width: 36px;
        height: 36px;
      }
    }
  }
}
</style>
