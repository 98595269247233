import Vue from 'vue'
import Popup from './popupList'

Popup.newInstance = props => {
  const _props = props || {}

  const PopupInstance = new Vue({
    data: _props,
    render (h) {
      return h(Popup, {
        props: _props,
      })
    },
  })

  const component = PopupInstance.$mount()

  document.body.appendChild(component.$el)
  const msgs = PopupInstance.$children[0]

  return {
    popup (msgProps) {
      msgs.add(msgProps)
    },
    remove (name) {
      msgs.close(name)
    },
    component: msgs,
    destroy (element) {
      msgs.closeAll()
      setTimeout(function () {
        document.body.removeChild(document.getElementsByClassName(element)[0])
      }, 500)
    },
  }
}

export default Popup
