<template>
  <section class="c-form-item c-form-select-item">
    <label>{{ label }}</label>
    <div class="input-wrap">
      <div class="item" @click="onClick(1)">
        <div class="item-choose" :class="{ active: 1 == value }">
          <div class="circle" />
        </div>
        <div class="lable">男</div>
      </div>

      <div class="item" @click="onClick(2)">
        <div class="item-choose" :class="{ active: 2 == value }">
          <div class="circle" />
        </div>
        <div class="lable">女</div>
      </div>

      <div class="item" @click="onClick(0)">
        <div class="item-choose" :class="{ active: 0 == value }">
          <div class="circle" />
        </div>
        <div class="lable">不显示</div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'FormSelectItem',
    props: {
      value: { type: [String, Number], default: '' },
      label: { type: String, default: '' },
      placeholder: { type: String, default: '' },
      readonly: { type: Boolean, default: false },
    },
    methods: {
      onClick (k) {
        this.$emit('click', k)
      },
    },
  }
</script>

<style lang="less" scoped>
@import url("./formItem.less");

.c-form-select-item {
  height: 100px;
  border-bottom: 1px solid @border-color !important; /* no */
  flex: 1;
  margin: 0 30px;
  padding: 0;
  color: #1a1a1a;
  font-size: 32px;
  label {
    letter-spacing: 32px;
    white-space:nowrap;
  }
  .input-wrap {
    border-color: transparent;
    justify-content: flex-start !important;
    .item {
      display: flex;
      align-items: center;
      margin-right: 48px;
      &-choose {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(204, 204, 204, 1);
        border-radius: 50%;
        margin-right: 12px;
        .circle {
          width: 50%;
          height: 50%;
          border-radius: 50%;
          background-color: #fff;
        }
        &.active {
          background-color: @primary;
          border: none;
        }
      }
    }
  }
}
</style>
