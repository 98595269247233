import api from './api'

// 聊天

/**
 * 创建聊天室
 */
export function createRoom (target) {
  return api.post(`/chats/room/${target}`, { validateStatus: s => s === 201 })
}

/**
 * 获取聊天列表
 */
export function getChats () {
  return api.get('/chats', { validateStatus: s => s === 200 })
}

/**
 * 获取聊天房间信息
 */
export function getRoom (roomId) {
  return api.get(`/chats/${roomId}`, { validateStatus: s => s === 200 })
}

/**
 * 获取聊天列表
 */
export function getMessages (roomId, params) {
  return api.get(`/chats/${roomId}/messages`, { params }, { validateStatus: s => s === 200 })
}

/**
 * 发文本消息
 */
export function sendTextMessage (roomId, data) {
  return api.post(`/chats/${roomId}/send-text`, data, { validateStatus: s => s === 201 })
}

/**
 * 发图片消息
 */
export function sendImgMessage (roomId, params) {
  return api.post(`/chats/${roomId}/send-image`, params, { validateStatus: s => s === 201 })
}

/**
 * 房间消息设置为0
 */
export function unCount (roomId, params) {
  return api.put(`/chats/${roomId}/unCount`, params, { validateStatus: s => s === 204 })
}
