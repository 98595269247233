import wx from 'weixin-js-sdk'
import {
  baseURL
} from '@/api'
import Message from '@/plugins/message-box'
import axios from 'axios'

const api = axios.create({
  baseURL
})

// const excludeObj = {}
let wxconfigable = false
let loadconfig = false
/**
 * 初始化 微信分享 配置
 * @author jsonleex <jsonlseex@163.com>
 */
const __JsApiList = [
  'scanQRCode',
  'checkJsApi',
  'onMenuShareAppMessage',
  'onMenuShareWechat',
  'onMenuShareTimeline',
  'shareAppMessage',
  'shareWechatMessage',
  'updateAppMessageShareData',
  'chooseImage',
  'getLocalImgData'
]

export default (url, options) => {
  if (!url) throw new Error('微信分享: 参数URL不能为空')
  // if (excludeObj[url]) return
  const ready = function (_options) {
    wx.onMenuShareAppMessage(_options)
    wx.onMenuShareTimeline(_options)
  }
  const shareObj = {
    imgUrl: '', // 分享图标
    desc: '维沙生活圈', // 分享描述
    title: '维沙生活圈', // 分享标题
    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致 默认当前路径,
    /**
     * 分享成功后的回调
     * @author jsonleex <jsonlseex@163.com>
     */
    success: () => {
      Message.success('分享成功')
    },
    /**
     * 取消时的回调
     * @author jsonleex <jsonlseex@163.com>
     */
    cancel: () => {
      Message.info('取消分享')
    },
  }
  options = {
    ...shareObj,
    ...options
  }
  shareObj.desc = options.desc ? options.desc : shareObj.desc
  shareObj.title = options.title ? options.title : shareObj.title
  const dataImg = JSON.parse(JSON.stringify(options))
  options.imgUrl = dataImg.imgUrl ? dataImg.imgUrl : options.imgUrl
  if (loadconfig && wxconfigable) {
    ready(options)
    return false
  }
  if (loadconfig) {
    // ready(options)
    return false
  }
  // console.log(url, 1111);
  api
    .post('socialite/wxconfig', {
      url: window.location.origin +
        process.env.BASE_URL.substr(0, process.env.BASE_URL.length)
    })
    .then(({
      data: {
        timestamp,
        signature,
        appid,
        noncestr
      }
    }) => {
      loadconfig = true
      const wxconfig = {
        beta: true,
        debug: false,
        timestamp,
        signature,
        appId: appid,
        nonceStr: noncestr,
        jsApiList: __JsApiList,
      }

      wx.config(wxconfig)
      wx.ready(() => {
        wxconfigable = true
        if (options.imgUrl && options.imgUrl.indexOf('file') !== -1) {
          // axios.get(`${options.imgUrl}?json=1`)
          //   .then(({ data: {url} }) => {
          //     options.imgUrl = url
          //   })
          //   .catch((e) => {
          //     console.log(e)
          //   })
          //   .finally(() => {
          //     ready(options)
          //   });
          ready(options)
        } else {
          // options.imgUrl = options.imgUrl[0] || `${process.env.VUE_APP_API_HOST}/default_icon.jpg`
          options.imgUrl = 'http://upyun.chinamani.com/2021/07/13/tu.jpg'
          ready(options)
        }
      })
      wx.error(err => {
        // eslint-disable-next-line
        console.warn('微信配置错误: ', err);
      })
    })
    .catch(err => {
      // eslint-disable-next-line
      console.warn(err.response.data);
    })
}
