<template>
  <div @touchmove.prevent>
    <Transition name="toast">
      <div v-if="show" class="m-pop-box" @click="cancel" />
    </Transition>
    <Transition name="pop">
      <div v-if="show" class="c-comment-input" @touch.prevent>
        <!-- <div class="tips" v-if="is_anonymous">当前动态可以匿名评论哦！</div> -->
        <span class="textarea-wrap" @touchmove.stop>
          <textarea
            ref="textarea"
            v-model.trim="contentText"
            :placeholder="placeholder"
            maxlength="5000"
            @blur="moveCurPos"
            @keydown.enter.prevent="sendText"
            @input="moveCurPos"
          />
          <textarea
            ref="shadow"
            :value="shadowText"
            :disabled="true"
            class="textarea-shadow"
            rows="1"
            maxlength="5000"
          />
        </span>
        <div class="submit-wrap">
          <!-- <span v-if="contentText.length >= 210" class="content-length"
            >{{ contentText.length }}/255</span
          > -->
          <!-- <div class="infoBtn">
            <div
              class="btn"
              :class="{ 'btn-active': anonymous }"
              @click="chooseAnonymous"
            >
              <div class="round"></div>
            </div>
            不显示昵称
          </div> -->
          <!-- <div class="anonymousBtn" >
            匿名 <md-switch v-model="anonymous"></md-switch>
          </div> -->
          <button
            :disabled="!contentText.length"
            class="submit-btn"
            @click="sendText"
          >
            {{ $t("send") }}
          </button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CommentInput",
  data() {
    return {
      curpos: 0,
      onOk: null,
      show: false,
      loading: false,
      scrollHeight: 0,
      contentText: "",
      placeholder: this.$t("comment_placeholder"),
      anonymous: false,
      is_anonymous: true,
      authorId: 0,
    };
  },
  computed: {
    ...mapState({
      user: "CURRENTUSER",
    }),
    shadowText() {
      return "blank" + this.contentText;
    },
    fullContentText() {
      return this.contentText;
    },
    textareaHeight() {
      return this.scrollHeight > 100 ? 100 : this.scrollHeight;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.scrollTop = document.scrollingElement.scrollTop;
        document.body.classList.add("m-pop-open");
        document.body.style.top = -this.scrollTop + "px";

        const txt = this.$lstore.getData("H5_COMMENT_SAVE_CONTENT");
        if (txt) {
          this.contentText = txt.trim() || "";
          this.curpos = this.contentText.length;
        }

        this.$nextTick(() => {
          this.$refs.textarea && this.$refs.textarea.focus();
        });
      } else {
        document.body.style.top = "";
        document.body.classList.remove("m-pop-open");
        document.scrollingElement.scrollTop = this.scrollTop;
      }
    },
    contentText(val, oval) {
      if (val !== oval) {
        this.$lstore.setData("H5_COMMENT_SAVE_CONTENT", val);
        this.$nextTick(() => {
          this.$refs.shadow &&
            (this.scrollHeight = this.$refs.shadow.scrollHeight);
        });
      }
    },
  },
  created() {
    this.$bus.$on("commentInput:close", (status) => {
      status && this.clean();
      this.cancel();
    });
    this.$bus.$on(
      "commentInput",
      ({ placeholder, is_anonymous, authorId, onOk }) => {
        typeof placeholder === "string" && (this.placeholder = placeholder);
        typeof onOk === "function" && (this.onOk = onOk);
        typeof is_anonymous === "boolean"
          ? (this.is_anonymous = is_anonymous)
          : "";
        typeof authorId === "number" && (this.authorId = authorId);
        if(is_anonymous&&this.user.id===authorId)this.anonymous=true
        // console.log("is_anonymous", is_anonymous, this.is_anonymous, authorId, this.user.id===authorId);
        this.show = true;
        this.$nextTick(() => {
          this.$refs.shadow &&
            (this.scrollHeight = this.$refs.shadow.scrollHeight);
        });
      }
    );
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.shadow && (this.scrollHeight = this.$refs.shadow.scrollHeight);
    });
  },
  destroyed() {
    this.clean();
  },
  methods: {
    chooseAnonymous() {
      this.anonymous = !this.anonymous;
      this.$refs.textarea && this.$refs.textarea.focus();
    },
    moveCurPos() {
      this.$refs.textarea && (this.curpos = this.$refs.textarea.selectionStart);
    },
    clean() {
      this.contentText = "";
      this.anonymous = false;
    },
    sendText() {
      if (this.loading) return;
      this.loading = true;

      this.onOk &&
        typeof this.onOk === "function" &&
        this.onOk(this.contentText, this.anonymous);

      this.cancel();
    },
    cancel() {
      this.placeholder = "随便说说~";
      this.loading = false;
      this.onOk = null;
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.c-comment-input {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  flex-wrap: wrap;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100px;
  padding: 20px;
  background-color: @body-bg;
  border-top: 1px solid @border-color; /*no*/
  .tips {
    width: 100%;
    text-align: left;
    font-size: 0.25rem;
    color: #999;
    margin-bottom: 0.17rem;
  }
  .textarea-wrap {
    flex: auto;
    display: flex;
    position: relative;
    font-size: 30px;
    word-wrap: break-word;
    width: 100%;
    height: 190px;
    background-color: #f6f6f6;
    padding: 16px 20px;

    textarea {
      line-height: 1.5;
      background-color: transparent;
      outline: 0;
      border: 0;
      font-size: inherit;
      resize: none;
      width: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
    }

    .textarea-shadow {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
  }

  .submit-wrap {
    display: flex;
    width: 100%;
    margin-top: 20px;
    .content-length {
      font-size: 20px;
      margin-bottom: 20px;
      color: #999;
    }
    .anonymousBtn {
      display: flex;
      font-size: 22px;
      align-items: center;
      .md-switch {
        margin-left: 5px;
      }
    }
    .infoBtn {
      display: flex;
      align-items: center;
      color: @text-color3;
      font-size: 26px;
      .btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 56px;
        height: 34px;
        margin-right: 12px;
        background: rgba(204, 204, 204, 1);
        border-radius: 17px;
        .round {
          width: 30px;
          height: 30px;
          margin: 0 2px;
          border-radius: 50%;
          background-color: #fff;
        }
        &-active {
          justify-content: flex-end;
          background-color: @primary;
        }
      }
    }
    .submit-btn {
      margin-left: auto;
      padding: 6px 23px;
      border-radius: 5px;
      background-color: @primary;
      font-size: 24px;
      color: #fff;

      &[disabled] {
        background-color: #cccccc;
      }
    }
  }
}
</style>
