<template>
  <span class="ut">
    <img
      v-if="user.sex == 1 || user.sex == 2"
      :src="sexPng"
      class="sign"
    >

    <img
      v-if="user.permission ? user.permission.is_developer : false"
      :src="require('@/images/official1@2x.png')"
      class="sign"
    >
    <img
      v-if="user.permission ? user.permission.is_vip : false"
      :src="require('@/images/v_c@2x.png')"
      class="sign"
    >
    <img
      v-if="user.permission ? user.permission.is_admin : false"
      :src="require('@/images/administrator_c@2x.png')"
      class="sign"
    >
    <img
      v-if="user.level && user.level.icon"
      :src="user.level.icon.url"
      class="sign"
    >
  </span>
</template>

<script>
  import _ from 'lodash'
  import * as userApi from '@/api/user'

  export default {
    name: 'UserTitle',
    props: {
      user: { type: Object, required: true },
      anonymity: { type: [Boolean, Number], default: false },
    },
    computed: {
      sexPng () {
        if (this.user.sex == 1) {
          return require('@/images/male@2x.png')
        } else if (this.user.sex == 2) {
          return require('@/images/female@2x.png')
        }
      },
    }
  }
</script>

<style lang="less" scoped>
.ut {
  display: inline-flex;
  align-items: center;
}
.sign {
  width: 25px;
  height: 25px;
  margin-left: 10px;
}
</style>
