import api from './api'
import Message from '@/plugins/message'

/**
 * 获取积分流水
 * @author mutoe <mutoe@foxmail.com>
 * @export
 * @param {Object} params
 * @param {number} [params.limit]
 * @param {number} [params.after]
 * @param {string} [params.action] - 筛选类型 [recharge: 充值记录, cash: 提现记录] 默认为全部
 * @param {number} [params.type] 收入类型 [1: 收入, -1: 支出] 默认为全部
 * @returns
 */
export function getCurrencyOrders (params) {
  return api.get('/currency/orders', {
    params,
    validateStatus: s => s === 200,
  })
}

/**
 * 发起充值
 * @author mutoe <mutoe@foxmail.com>
 * @export
 * @param {Object} data
 * @param {string} data.type 充值方式
 * @param {number} data.amount 充值金额(单位：RMB分)
 * @param {number} data.from 来自哪个端 h5固定为2
 * @returns
 */
export function postCurrencyRecharge (data) {
  const url = '/currencyRecharge/orders'
  return api.post(
    url,
    { ...data, from: 2 },
    { validateStatus: s => s === 201 },
  )
}

/**
 * 发起积分提现
 * @author mutoe <mutoe@foxmail.com>
 * @export
 * @param {Object} data
 * @param {number} data.amount
 * @returns
 */
export function postCurrencyWithdraw (data) {
  return api
    .post('/currency/cash', data, {
      validateStatus: s => s === 201,
    })
    .catch(({ response }) => {
      Message.error(response.data)
    })
}

/**
 * 查询微信支付订单
 *
 * @author mutoe <mutoe@foxmail.com>
 * @export
 * @param {*} order
 * @returns
 */
export function checkWechatOrders (order) {
  const url = '/walletRecharge/checkWechatOrders'
  return api.post(url, { out_trade_no: order }, { validateStatus: s => s === 200 })
}

/**
 * 积分任务
 *
 * @export
 * @returns
 */
export function getTask () {
  return api.get('/currency/task', { validateStatus: s => s === 200 })
}

/**
 * chaxun 查询开奖配置
 *
 * @export
 * @returns
 */
 export function getPrize (limit,offset) {
  return api.get(`/vs/lotteryConfig?limit=${limit}&offset=${offset}`, { validateStatus: s => s === 200 }).catch(({ response }) => {
    Message.error(response.data)
  })
}

/**
 * chaxun 查询开奖配置
 *
 * @export
 * @returns
 */
 export function getPrizeDetail (id) {
  return api.get(`/vs/lotteryDetail?lottery_id=${id}`, { validateStatus: s => s === 200 }).catch(({ response }) => {
    Message.error(response.data)
  })
}

/**
 * chaxun 确认兑换
 *
 * @export
 * @returns
 */
 export function prizeExchange (id,num) {
  return api.get(`/vs/lotteryExchange?lottery_id=${id}&lottery_count=${num}`, { validateStatus: s => s === 200 }).catch(({ response }) => {
    Message.error(response.data)
  })
}

/**
 * chaxun 确认兑换
 *
 * @export
 * @returns
 */
 export function getMyPrizeList (status,limit,offset) {
  return api.get(`/vs/lotteryRecord?status=${status}&limit=${limit}&offset=${offset}`, { validateStatus: s => s === 200 }).catch(({ response }) => {
    Message.error(response.data)
  })
}
