<template>
  <!-- 积分商城的页面 -->
  <div class="integralStore">
    <div class="integera-box">

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.integera-box{
  width: 100%;
  height: 4rem;
  background: cadetblue;
}
</style>