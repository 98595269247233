// import MD5 from 'js-md5'
import http from '@/api/api.js'
import SparkMD5 from 'spark-md5'
export async function hashFile (file) {
  return new Promise(resolve => {
    const reader = new FileReader()
    let BlobSlice = File.prototype.mozSlice || File.prototype.webkitSlice || File.prototype.slice; //用于支持多种浏览器的文件读取
    let BlockSize = 104857600; //要把文件分成一块一块的，这样可以应对巨大的文件而不至于出错，这里将文件以10M为单位进行分割。
    let Blocks = Math.ceil(file.size / BlockSize); //首先计算需要分割的块数
    let CurrentBlock = 0; //记录当前已经运行的块数
    let start = 0;
    let end = start + BlockSize >= file.size ? file.size: start + BlockSize;
    reader.readAsBinaryString(BlobSlice.call(file, start, end));
    const md5 = new SparkMD5();
    // debugger
    reader.onload = event => {
      md5.appendBinary(event.target.result);
      CurrentBlock++; //文件区块后移
      if (CurrentBlock < Blocks) { //判断是否到达文件末尾
        start = CurrentBlock * BlockSize; //更新文件区块开始结束的位置
        end = start + BlockSize >= file.size ? file.size : start + BlockSize;
        reader.readAsBinaryString(BlobSlice.call(file, start, end)); //加载下一个区段
      }else{
        resolve(md5.end())
      }
    }
  })
}



function existed (file) {
  return hashFile(file).then(hash => {
    http
      .get(`/files/uploaded/${hash}`, {
        validateStatus: s => s === 404 || s === 200,
      })
      .then(({ status, data: { id } }) =>
        Promise.resolve(status === 200 && id > 0 ? id : false),
      )
      .catch(err => Promise.reject(err))
  })
}

function sendImage (file,url) {


  var img_url = url
  console.log(url)
// 创建对象
  var img = new Image()

// 改变图片的src
  img.src = img_url
  let width = 0
  let height = 0
// 判断是否有缓存
  if(img.complete){
    width = img.width
    height = img.height
  }else{
    // 加载完成执行
    img.onload = function(){
      width = img.width
      height = img.height
    }
  }

  const formData = new FormData()
  formData.append('file', file)
  formData.append('width', width)
  formData.append('height', height)
  return new Promise((resolve, reject) => {
    http
      .post('/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data: { id } }) => {
        if (id && id > 0) {
          resolve(id)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

export default (file,url) => {
  return new Promise((resolve, reject) => {
    existed(file)
      .then(status => {
        status && typeof status === 'number'
          ? resolve(status)
          : sendImage(file,url).then(id => resolve(id), err => reject(err))
      })
      .catch(err => {
        reject(err)
      })
  })
}
