<template>
  <div class="level-container">
    <CommonHeader>
      {{ $t('profile.level.name') }}
    </CommonHeader>

    <div class="table">
      <div class="table-header">
        <div class="th">等级</div>
        <div class="th">等级名称</div>
        <div class="th">等级图标</div>
        <div class="th">动态及评论数</div>
      </div>
      <div class="table-content">
        <div class="raw" v-for="item in list">
          <div class="td">{{ item.id }}</div>
          <div class="td">{{ item.name }}</div>
          <div class="td">
            <img class="icon" :src="item.icon.url" alt="">
          </div>
          <div class="td">发布动态数 {{ item.post_num_min }}~{{ item.post_num_max }}<br/>发布评论数
            {{ item.comment_num_min }}~{{ item.comment_num_max }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as userApi from '@/api/user'
export default {
  name: "ProfileLevel",
  data() {
    return {
      list: [],
    }
  },
  computed: {
    after () {
      const len = this.list.length
      return len ? this.list[len - 1].id : 0
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    async fetch() {
      const { data } = await userApi.getLevel()
      this.list = data
    },
  }
}
</script>

<style lang="less" scoped>
.level-container {
  background: #fff;
  padding-bottom: 114px;
}
.table {
  width: 690px;
  margin: 19px auto 114px;
  border: 1px solid @border-color;

  &-header {
    display: flex;
    font-size: 24px;
    color: #fff;
    background: #adb1ba;
  }
  &-content {
    font-size: 20px;
    color: #808080;
    .raw {
      display: flex;
      &:nth-child(2n) {
        background: #f7f7f7;
        border-top: 1px solid @border-color;
      }
    }
  }

  .th, .td {
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(:last-child) {
      border-right: 1px solid @border-color;
    }
    &:nth-child(1) {
      width: 88px;
    }
    &:nth-child(2), &:nth-child(3) {
      width: 138px;
    }
    &:nth-child(4) {
      width: 323px;
    }
  }
  .th {
    height: 70px;
  }
  .td {
    height: 108px;
  }

  .icon {
    width: 33px;
    height: 28px;
  }
}
</style>
