// vuex utils
import { mapActions, mapState } from 'vuex'
import CONST from './constants/app'

// components
import PhotoSwipe from './components/PhotoSwipe'
import PostMenu from './page/PostMenu.vue'
import PayFor from './components/PayFor.vue'
import ActionSheet from './components/ActionSheet.vue'
import CommentInput from './components/CommentInput.vue'
import CheckIn from './page/checkin/CheckIn.vue'
import Reward from './components/Reward.vue'
import Report from './components/Report.vue'
import ChooseTags from './page/ChooseTags.vue'
import ChooseContentTags from './page/ChooseContentTags.vue'
import ChooseAreaTags from './page/ChooseAreaTags.vue'
import ApplyTop from './components/ApplyForTop.vue'
import MovePost from './components/MovePost.vue'
import PopupDialog from './components/PopupDialog.vue'
import PopupConfirm from './components/PopupConfirm.vue'
import ChatWithQQ from './components/vendor/ChatWithQQ.vue'
import wechatShare from '@/util/wechatShare'

export default {
  render () {
    return (
      <div id="app" class="wap-wrap">
        <keep-alive>{this.keepAlive && <router-view />}</keep-alive>
        {!this.keepAlive && <router-view />}
        <div>
          <PhotoSwipe />
          <PostMenu />
          <PayFor />
          <ActionSheet />
          <CommentInput />
          <CheckIn />
          <Reward />
          <Report />
          <ChooseTags />
          <ChooseContentTags />
          <ChooseAreaTags />
          <ApplyTop />
          <MovePost />
          <PopupDialog />
          <PopupConfirm />
          {CONST.ENABLE_QQ_CONSULT && <ChatWithQQ />}
        </div>
      </div>
    )
  },
  name: 'App',
  data() {
    return {
      excludeList: [ // 具体路由正则从$route下复制
        /^\/users\/((?:\d+))(?:\/(?=$))?$/i, // 个人主页
        /^\/groups\/((?:\d+))\/posts\/((?:\d+))(?:\/(?=$))?$/i, // 帖子详情
        /^\/groups\/((?:\d+))(?:\/(?=$))?$/i, // 板块详情
      ]
    }
  },
  computed: {
    keepAlive () {
      return this.$route.meta.keepAlive || false
    },
    ...mapState({
      /**
       * Global user id.
       *
       * @param  {Object} state
       *
       * @return {number}
       */
      UID: state => state.CURRENTUSER.id,
      /**
       * Easemob status.
       *
       * @param  {Object} state
       *
       */
      status: state => state.EASEMOB.status,
    }),
    isWechat () {
      return this.$store.state.BROWSER.isWechat
    },
  },
  watch: {
    $route (to, from) {
      if (to.meta.title) document.title = to.meta.title

      // 排除路由
      const pathname = this.$route.path
      if (this.excludeList.some((regExp) => regExp.test(pathname))) return;
      this.share()
    },
  },
  methods: {
    ...mapActions({bootstrapHandle: 'BOOTSTRAPPERS',}),
    share() {
      // 微信分享
      if (this.isWechat) {
        const shareUrl =
          window.location.origin +
          process.env.BASE_URL.substr(0, process.env.BASE_URL.length - 1) +'/#/'
          this.$route.fullPath
        const signUrl =
          this.$store.state.BROWSER.OS === 'IOS' ? window.initUrl : shareUrl
        wechatShare(signUrl)
      }
    }
  },
  /**
   * The created hook.
   * @return {void}
   */
  created () {
    this.bootstrapHandle()
    this.share()
  },
}
