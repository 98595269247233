import Message from './messageInstance'
import Popup from './popupInstance'
import Notice from './noticeInstance'

const prefixCls = 'v-msg'
const prefixKey = 'v_message_key_'

const defaults = {
  duration: 3,
}

let messageInstance
let popupInstance
let noticeInstance
let name = 1

const iconTypes = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
  loading: 'loading',
  suceed: 'suceed',
  failure: 'failure',
}

function getMessageInstance () {
  messageInstance = messageInstance || Message.newInstance()
  return messageInstance
}
function getPopupInstance () {
  popupInstance = popupInstance || Popup.newInstance()
  return popupInstance
}
function getNoticeInstance () {
  noticeInstance = noticeInstance || Notice.newInstance()
  return noticeInstance
}

function notice (
  type,
  content = '',
  closable = false,
  onClose = function () {},
  duration = defaults.duration,
) {
  const iconType = iconTypes[type]

  const instance = getMessageInstance()

  instance.notice({
    content,
    styles: {},
    icon: iconType,
    type: 'message',
    onClose: onClose,
    closable: closable,
    duration: duration,
    transitionName: 'move-up',
    name: `${prefixKey}${name}`,
  })

  return (function () {
    const target = name++

    return function () {
      instance.remove(`${prefixKey}${target}`)
    }
  })()
}

function popup (
  type,
  content = '',
  closable = false,
  onClose = function () {},
  duration = defaults.duration,
) {
  const iconType = iconTypes[type]
  const instance = getPopupInstance()

  instance.popup({
    content,
    styles: {},
    icon: iconType,
    type: 'message',
    onClose: onClose,
    closable: closable,
    duration: duration,
    transitionName: 'move-up',
    name: `${prefixKey}${name}`,
  })

  return (function () {
    const target = name++

    return function () {
      instance.remove(`${prefixKey}${target}`)
    }
  })()
}

function noticeX (
  type,
  content = '',
  closable = false,
  onClose = function () {},
  duration = defaults.duration,
) {
  const iconType = iconTypes[type]
  const instance = getNoticeInstance()

  instance.notice({
    content,
    styles: {},
    type: 'message',
    onClose: onClose,
    closable: closable,
    duration: duration,
    transitionName: 'move-up',
    name: `${prefixKey}${name}`,
  })

  return (function () {
    const target = name++

    return function () {
      instance.remove(`${prefixKey}${target}`)
    }
  })()
}

export default {
  name: 'Message',

  info (options) {
    return this.message('info', options)
  },
  success (options) {
    return this.message('success', options)
  },
  warning (options) {
    return this.message('warning', options)
  },
  error (options) {
    return this.message('error', options)
  },
  loading (options) {
    return this.message('loading', options)
  },
  message (type, options) {
    if (typeof options === 'string') {
      options = {
        content: options,
      }
    }
    return notice(
      type,
      options,
      options.closable,
      options.onClose,
      options.duration,
    )
  },
  popupSuccess (options) {
    return this.popupM('suceed', options)
  },
  popupFail (options) {
    return this.popupM('failure', options)
  },
  popupM (type, options) {
    if (typeof options === 'string') {
      options = {
        content: options,
      }
    }
    return popup(
      type,
      options,
      options.closable,
      options.onClose,
      options.duration,
    )
  },
  noticeMessage (options) {
    return this.noticeM('failure', options)
  },
  noticeM (type, options) {
    if (typeof options === 'string') {
      options = {
        content: options,
      }
    }
    return noticeX(
      type,
      options,
      options.closable,
      options.onClose,
      options.duration,
    )
  },
  config (options) {
    if (options.duration || options.duration === 0) {
      defaults.duration = options.duration
    }
  },
  destroy () {
    const instance = getMessageInstance()
    messageInstance = null
    instance.destroy(prefixCls)
  },
}
