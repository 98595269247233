<template>
  <div class="p-setting">
    <CommonHeader>
      <span slot="left" @click.prevent.stop="goProfile">
        <img
          :src="require('@/images/back@2x.png')"
          class="m-style-svg m-svg-def ico-back"
        >
      </span>
    </CommonHeader>

    <main class="main">
      <ul class="m-box-model m-entry-group padding">
        <RouterLink
          to="/feedback"
          tag="li"
          class="m-entry"
        >
          <span>意见反馈</span>
          <svg class="m-style-svg m-svg-def m-entry-append">
            <use xlink:href="#icon-arrow-right" />
          </svg>
        </RouterLink>
<!--        <RouterLink
          v-if="passwordHidden"
          to="/changePassword"
          tag="li"
          class="m-entry"
        >
          <span>{{ $t('auth.change_password.name') }}</span>
          <svg class="m-style-svg m-svg-def m-entry-append">
            <use xlink:href="#icon-arrow-right" />
          </svg>
        </RouterLink>-->

        <li class="m-entry" @click="aboutUs">
          <span class="m-box m-text-box m-flex-grow1">{{ $t('setting.about.name') }}</span>
          <!-- <span class="m-box m-text-box m-flex-grow1 m-justify-end m-entry-extra">v{{ version }}</span> -->
          <svg class="m-style-svg m-svg-def m-entry-append">
            <use xlink:href="#icon-arrow-right" />
          </svg>
        </li>
        <li class="m-entry" @click="userAgreement">
          <span class="m-box m-text-box m-flex-grow1">用户协议</span>
          <svg class="m-style-svg m-svg-def m-entry-append">
            <use xlink:href="#icon-arrow-right" />
          </svg>
        </li>
        <li class="m-entry" @click="PrivacyAgreement">
          <span class="m-box m-text-box m-flex-grow1">隐私政策</span>
          <svg class="m-style-svg m-svg-def m-entry-append">
            <use xlink:href="#icon-arrow-right" />
          </svg>
        </li>
<!--        <li class="m-entry" @click="switchHidden">
          <span>隐藏发布记录</span>
          <span class="m-box m-text-box m-flex-grow1 m-justify-end m-entry-extra">
            <span class="switch-btn" :class="isHidden ? 'active' : ''">
              <i />
            </span>
          </span>
        </li>-->

        <li class="m-entry mt20 text-center" @click="signOut">{{ $t('setting.logout.name') }}</li>
      </ul>
    </main>
  </div>
</template>

<script>
  import { version } from '@/main'
  import { mapState } from 'vuex'

  export default {
    name: 'Settings',
    data () {
      return {
        version,
        isHidden: false,
        disable: false,
        passwordHidden: true,
      }
    },
    computed: {
      ...mapState(['CURRENTUSER']),
      locale () {
        return this.$i18n.locale
      },
    },
    created () {
      this.isHidden = !!this.CURRENTUSER.hidden
      this.passwordHidden = !!this.CURRENTUSER.background
    },
    methods: {
      goProfile () {
        this.$router.push('/profile')
      },
      signOut () {
        const actions = [
          {
            text: this.$t('setting.logout.label'),
            style: { color: '#f4504d' },
            method: () => {
              this.$store.dispatch('SIGN_OUT')
              this.$nextTick(() => {
                this.$router.push('/signin')
              })
            },
          },
        ]
        this.$bus.$emit('actionSheet', actions, this.$t('cancel'), this.$t('setting.logout.confirm'))
      },
      aboutUs () {
        const { aboutUs = {} } = this.$store.state.CONFIG.site
        if (aboutUs.url) return (location.href = aboutUs.url)
        this.$router.push('/about')
      },
      userAgreement () {
        this.$router.push('/user-agreement')
      },
      PrivacyAgreement () {
        this.$router.push('/privacy-agreement')
      },
      switchLocale () {
        const target = this.locale === 'en' ? 'zh-CN' : 'en'
        this.$lstore.setData('I18N_LOCALE', target)
        this.$i18n.locale = target
        location.reload()
      },
      switchHidden () {
        if (this.disable) {
          return
        }
        this.disable = true
        const param = {
          hidden: !this.isHidden ? 1 : 2,
        }
        this.$http
          .patch('/user', param, { validateStatus: s => s === 204 })
          .then(() => {
            if (this.isHidden) {
              this.isHidden = false
            } else {
              this.isHidden = true
            }
            this.$store.commit(
              'SAVE_CURRENTUSER',
              Object.assign(this.CURRENTUSER, { param }),
            )
          })
          .catch(err => err)
          .finally(() => {
            this.disable = false
          })
      },
    },
  }
</script>
<style lang="less" scoped>
.main {
  margin-top: 24px;

  .m-entry-group {
    background: unset;

    .m-entry {
      background: #fff;
    }
  }

  .switch-btn {
    width: 56px;
    height: 34px;
    background: #ccc;
    opacity: 1;
    border-radius: 17px;
    position: relative;
  }
  .switch-btn > i {
    width: 30px;
    height: 30px;
    background: rgba(255,255,255,1);
    box-shadow: 0px 3px 6px rgba(0,0,0,0.2);
    opacity: 1;
    border-radius: 17px;
    position: absolute;
    top: 1px;
    left: 1px;
  }
  .switch-btn.active {
    background:#44C47A;
  }
  .switch-btn.active > i {
    left: auto;
    right: 1px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .text-center {
    text-align: center;
    color: @primary;
    display: block;
    line-height: 100px;
  }
}
</style>
