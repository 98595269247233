<template>
  <Transition name="popr">
    <div v-if="show" class="m-box-model m-pos-f p-choose-tags m-main">
      <CommonHeader :back="nextFuc" :head_title="$t('tags.content.select')">
        <span
          slot="right"
          :class="{ disabled }"
          class="check-vote"
          @click="nextFuc"
        >
          确认
        </span>
      </CommonHeader>

      <main class="m-box-model m-flex-grow1 m-flex-shrink1">
        <section class="m-flex-grow0 m-flex-shrink0 m-tags-group selected">
          <span class="m-tags-label">{{ $t('tags.placeholder', [2, chooseTags.length]) }}</span>
        </section>
        <div class="m-flex-grow1 m-flex-shrink1" style="overflow-y: auto;">
          <section v-if="hot.length > 0" class="m-tags-group">
            <span class="m-tags-label">热门标签：</span>
            <TransitionGroup tag="ul" class="m-tags">
              <li
                v-for="(ht) in hot"
                :key="ht.tag_id"
                :class="{ selected: hasCheck(ht) }"
                class="m-tag"
                @click="selectTag(ht)"
              >
                <span>{{ ht.name }}</span>
              </li>
            </TransitionGroup>
          </section>

          <section class="m-tags-group">
            <span class="m-tags-label">全部标签：</span>
            <TransitionGroup tag="ul" class="m-tags">
              <li
                v-for="(tag, Tindex) in tags"
                v-if="tag.id"
                :key="tag.id"
                :class="{ selected: tag.selected }"
                class="m-tag"
                @click="checkTag(tag, Tindex)"
              >
                <span>{{ tag.name }}</span>
              </li>
            </TransitionGroup>
          </section>
        </div>
      </main>
    </div>
  </Transition>
</template>

<script>
  import { noop } from '@/util'

  /**
   * 打开选择标签页面 (钩子 -> "choose-tags")
   * @author mutoe <mutoe@foxmail.com>
   * @param {Object} options
   * @param {number[]} options.chooseTags 初始选择值, 只需传 [tag.id], eg: [1, 2, 3,...]
   * @param {Function} options.nextStep 点击下一步的回调, 注入已选择的 tags
   * @param {Function} options.onSelect 选择某个标签时执行的回调函数
   * @param {Function} options.onRemove 取消选择某个标签时执行的回调函数
   */
  function onChooseTags ({ chooseTags = [], nextStep, onSelect, onRemove }) {
    this.isFirst = !this.$lstore.hasData('H5_CHOOSE_TAGS_FIRST')
    this.nextStep = nextStep || noop
    this.onSelect = onSelect || noop
    this.onRemove = onRemove || noop

    if (chooseTags && chooseTags.length > 0) {
      this.tags.forEach((t, Tindex) => {
        t.Tindex = Tindex
        if (chooseTags.indexOf(t.id) > -1) {
          t.selected = true
          this.chooseTags.push(t)
        }
      })
    }

    this.show = true
    this.scrollable = false

    // // 首次进入标签选择页面时弹框提示
    // if (this.isFirst && this.$route.name !== 'groupCreate' && this.$route.name !== 'groupCreatePost') {
    //   this.$nextTick(() => {
    //     this.$bus.$emit('popupDialog', {
    //       title: this.$t('tags.first_tips.title'),
    //       content: this.$t('tags.first_tips.content'),
    //       onClose: () => {
    //         this.onReadTips()
    //       },
    //     })
    //   })
    // }
  }

  export default {
    name: 'ChooseContentTags',
    data () {
      return {
        show: false,
        isFirst: this.$lstore.getData('H5_CHOOSE_TAGS_FIRST') || true,
        chooseTags: [],
        loading: false,
        tags: [],
        hot: [],
      }
    },
    computed: {
      disabled () {
        return this.chooseTags.length === 0
      },
    },
    created () {
      this.fetchTags()

      // 注册钩子
      this.$bus.$on('choose-content-tags', onChooseTags.bind(this))
    },
    methods: {
      nextFuc () {
        this.nextStep(this.chooseTags)
        this.$nextTick(this.cancel)
      },
      nextStep: noop,
      onRemove: noop,
      onSelect: noop,
      fetchTags () {
        this.$http.get('/1/tags').then(({ data: { hot, list,usedhot,usedlist } }) => {
          // this.hot = hot
          // this.tags = list
          this.hot  = usedhot  //不带匿名标签
          this.tags = usedlist //不带匿名标签的
        })
      },
      hasCheck (hot) {
        const tag = this.tags.find((item) => { return item.id == hot.tag_id })
        if (tag.hasOwnProperty('selected') && tag.selected) {
          return true
        }
        return false
      },
      checkTag (tag, Tindex) {
        if (this.chooseTags.indexOf(tag) < 0) {
          this.addTag(tag, Tindex)
        } else {
          this.removeTag(tag)
        }
      },
      selectTag (tag) {
        const Tindex = this.tags.findIndex((item) => { return item.id == tag.tag_id })
        tag = this.tags[Tindex]
        if (this.chooseTags.indexOf(tag) < 0) {
          this.addTag(tag, Tindex)
        } else {
          this.removeTag(tag)
        }
      },
      addTag (tag, Tindex) {
        const obj = this.tags[Tindex]
        if (obj.selected) return

        if (this.chooseTags.length >= 2) { return this.$Message.error(this.$t('tags.content.max_tips', [2])) }

        const status = { selected: true, Tindex }
        Object.assign(obj, status)
        this.chooseTags.push(obj)

        // emit hooks
        this.onSelect(tag.id)
      },
      removeTag (tag) {
        this.chooseTags.splice(this.chooseTags.indexOf(tag), 1)
        this.tags[tag.Tindex].selected = false

        // emit hooks
        this.onRemove(tag.id)
      },

      cancel () {
        this.chooseTags.forEach(tag => {
          delete tag.Tindex
          delete tag.selected
        })

        this.show = false
        this.chooseTags = []
        this.scrollable = true
      },

      onReadTips () {
        this.$lstore.setData('H5_CHOOSE_TAGS_FIRST', false)
      },
    },
  }
</script>
<style lang="less" scoped>
.p-choose-tags {
  .check-vote {
    color: @primary;
  }
  .check-vote.disabled {
    color: #ccc;
  }
  main {
    overflow-y: auto;
    height: calc(~"100vh - 90px");
  }
  .m-tags-group {
    padding: 0 30px;
    margin-top: 30px;
    &:last-of-type {
      padding-bottom: 30px;
    }
  }
  .m-tags-label {
    font-size: 26px;
    color: @text-color3;
  }

  .m-tags {
    margin-left: -30px;
    min-height: 90px;

    .m-svg-def {
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
      fill: #b3b3b3;
      transform: translate(-50%, -50%);
    }
  }

  .m-tag {
    position: relative;
    margin: 30px 0 0 30px;
    width: calc((1 / 3 * 100%) ~" - 30px");
    height: 60px;
    line-height: 60px;
    border-radius: 32px;
    &.selected {
      background-color: @primary;
      color: #fff;
    }
    span {
      overflow: hidden;
      width: 100%;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
  }
}
</style>
