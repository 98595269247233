<template>
  <div class="feedback-box">
    <CommonHeader />

    <main class="main">
      <div class="con-con">
        <TextareaInput
          v-model="content"
          :maxlength="200"
          :rows="10"
          :warnlength="0"
          class="content-box"
          placeholder="请输入反馈，我们将不断为您改进"
        />
      </div>

      <div
        class="text-center"
        :class="{ disabled }"
        @click="postFeedback"
      >
        提交
      </div>
    </main>
  </div>
</template>

<script>
  import * as api from '@/api'
  import TextareaInput from '@/components/common/TextareaInput.vue'

  export default {
    name: 'Feedback',
    components: {
      TextareaInput,
    },
    data () {
      return {
        content: '',
        loading: false,
      }
    },
    computed: {
      disabled () {
        const length = this.content.length
        return length < 1 || length > 200
      },
    },
    methods: {
      postFeedback () {
        if (this.disabled || this.loading) return
        this.loading = true
        const params = {
          content: this.content,
          type: 2,
          mark: new Date().valueOf() + '' + this.$store.state.CURRENTUSER.id,
        }
        api.feedback(params).then(({ data }) => {
          this.content = ''
          this.$Message.success(data)
          this.loading = false
        })
          .catch(({ response: { data } = {} }) => {
            this.$Message.error(data)
            this.loading = false
          })
      },
    },
  }
</script>

<style lang='less' scoped>
.feedback-box {
  background: #fff;
  height: 100%;

  .main {
    padding: 0 30px;

    .con-con {
      font-size: 30px;

      .content-box {
        background: #F6F6F6;
        padding: 24px;
        margin-bottom: 10px;

        /deep/ .word-length {
          color: #B2B2B2;
          font-size: 24px;
          bottom: -45px;
        }
      }
    }

    .text-center {
      margin-top: 100px;
      width: 100%;
      height: 80px;
      line-height: 80px;
      background: @primary;
      color: #fff;
      text-align: center;
      border-radius: 6px;
    }

    .text-center.disabled {
      background: #ccc;
    }
  }
}
</style>
