<template>
  <div
    class="feed-video"
    @click.stop="clickHandler"
  >
    <video
      v-if="video.resource"
      :ref="`video_${id}`"
      :poster="video.cover && video.cover.url"
      :controls="paid && play"
      :class="{'fuzzy': !paid}"
      x-webkit-airplay="allow"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="true"
      x5-video-orientation="portraint"
      webkit-playsinline="true"
      playsinline="true"
    >
      <source
        :src="video.resource.url"
        type="video/mp4"
      >
      Your browser does not support the video tag.
    </video>
    <img v-if="!play" class="play" @click="onPlay" src="@/images/ico_video_play@2x.png" alt="">
  </div>
</template>
<script>
  export default {
    name: 'FeedVideo',
    props: {
      video: {
        type: Object,
        required: true,
      },
      id: {
        type: Number,
        required: true,
      },
      paid: { type: Boolean, default: true },
    },
    data: () => ({
      videoFile: '',
      coverFile: '',
      play: false,
      ratio: 1,
    }),
    computed: {
      height () {
        return this.video.width < this.video.height
          ? 5.18
          : parseInt(this.video.height * this.ratio) / 100
      },
    },
    created () {
      this.ratio = 518 / this.video.width
    },
    mounted() {
      this.$refs[`video_${this.id}`].addEventListener('play', this.launchFullscreen)
      this.$refs[`video_${this.id}`].addEventListener('ended', this.exitFullscreen)
    },
    beforeDestroy() {
      this.$refs[`video_${this.id}`].addEventListener('ended', this.exitFullscreen)
    },
    methods: {
      onPlay() {
        if (this.paid) {
          this.play = true
          this.$refs[`video_${this.id}`].play()
        }
      },
      clickHandler() {
        if (!this.paid) {
          this.$emit('pay')
        }
      },
      // 进入全屏
      launchFullscreen() {
        // console.log('进入全屏')
        const element = this.$refs[`video_${this.id}`]
        //此方法不可以在異步任務中執行，否則火狐無法全屏
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen()
        } else if (element.oRequestFullscreen) {
          element.oRequestFullscreen()
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullScreen()
        }
      },
      // 退出全屏
      exitFullscreen() {
        console.log('退出全屏')
        const element = this.$refs[`video_${this.id}`]
        if (element.exitFullscreen) {
          element.exitFullscreen()
        } else if (element.msExitFullscreen) {
          element.msExitFullscreen()
        } else if (element.mozCancelFullScreen) {
          element.mozCancelFullScreen()
        } else if (element.oRequestFullscreen) {
          element.oCancelFullScreen()
        } else if (element.webkitExitFullscreen) {
          element.webkitExitFullscreen()
        }
      }
  },
  }
</script>
<style lang="less" scoped>
.feed-video {
  overflow: hidden;
  width: 518px;
  height: 518px;
  position: relative;
  video {
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: block;
    margin: auto;
  }
}
.fuzzy {
  filter: blur(15px);
}
.play {
  width: 65px;
  height: 65px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: pointer;
}
</style>
