<template>
  <header :class="{ noborder: noborder, pinned }" class="c-common-header">
    <div class="left" @click="onBackClick">
      <slot name="left">
        <img
          :src="require('@/images/back@2x.png')"
          class="m-style-svg m-svg-def ico-back"
        >
      </slot>
    </div>
    <div class="title">
      <div class="title-wrap"><slot /></div>
    </div>
    <div class="right">
      <slot name="right" />
      <a
        v-if="more"
        class="m-box-model m-aln-center"
        style="color:#000"
        @click="handelMore"
      >
        <svg class="m-style-svg m-svg-def">
          <use xlink:href="#icon-more" />
        </svg>
      </a>
    </div>
  </header>
</template>

<script>
  import { noop } from '@/util'
  import * as DD from 'dingtalk-jsapi'

  export default {
    name: 'CommonHeader',
    props: {
      back: { type: Function, default: noop },
      pinned: { type: Boolean, default: false },
      more: { type: Boolean, default: false },
      noborder: { type: Boolean, default: false },
      head_title: { type: String, default: undefined },
    },
    data () {
      return {
        hTitle: '',
      }
    },
    created () {
      this.hTitle = document.title
      if (this.head_title) {
        document.title = this.head_title

        if (process.env.VUE_APP_DING_CORP_ID && DD.env.platform !== 'notInDingTalk') {
          DD.ready(function () {
            DD.biz.navigation.setTitle({
              title: document.title,
            })
          })
        }
      }
    },
    beforeDestroy () {
      if (this.head_title) {
        document.title = this.hTitle

        if (process.env.VUE_APP_DING_CORP_ID && DD.env.platform !== 'notInDingTalk') {
          DD.ready(function () {
            DD.biz.navigation.setTitle({
              title: document.title,
            })
          })
        }
      }
    },
    methods: {
      onBackClick () {
        if (this.back === noop) this.goBack()
        else this.back()
      },
      handelMore () {
        this.$emit('more')
      },
    },
  }
</script>

<style lang="less" scoped>
@header-height: 90px;

.c-common-header {
  display: flex;
  flex: none;
  position: relative;
  bottom: initial;
  width: 100%;
  height: @header-height;
  max-width: 768px;
  margin: 0 auto;
  justify-content: space-between;
  overflow: hidden;
  background: #fff;
  // border-bottom: 1px solid #ededed; /* no */
  font-size: 32px;
  color: inherit;
  z-index: 10;

  &.pinned {
    position: fixed;
  }
  &.noborder {
    border-color: transparent;
  }
  .left,
  .right,
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left,
  .right {
    flex: none;
    width: fit-content;
    min-width: @header-height;
    padding: 0 0.5em;
  }
  .title {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 16em;
    margin: 0 auto;
    text-align: center;

    > .title-wrap {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}
</style>

<style lang="less">
.c-common-header.pinned + * {
  padding-top: 90px;
}
</style>
