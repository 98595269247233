<template>
  <div class="m-lim-width m-art-comment">
    <div class="m-box m-art-comment-wrap m-avatar">
      <Avatar :user="user" />
      <section class="m-box-model m-flex-grow1 m-flex-shrink1 m-art-comment-body">
        <div>
          <header class="m-box m-aln-center m-justify-bet m-art-comment-usr">
            <div>
              <h4 class="m-flex-grow1 m-flex-shrink1">
                {{ userName }}
                <img
                  v-if="comment.excellent_at"
                  :src="require('@/images/wonderful@2x.png')"
                  class="wonderful"
                >
              </h4>
              <span>{{ time | time2tips }}</span>
            </div>
            <span class="anonymous-tag">匿名发布</span>
          </header>
          <article class="m-text-box m-art-comment-con">
            <template v-if="replyUser">
              <!-- <span class="m-art-comment-rep">
                回复 <RouterLink :to="`/users/${replyUser.id}`">{{ replyUser.name }}</RouterLink>：
              </span> -->

              <span v-if="!replyAnonymous" class="m-art-comment-rep">
                回复 <RouterLink :to="`/users/${replyUser.id}`">{{ replyUser.name }}</RouterLink>： 
              </span>
              <span v-else class="m-art-comment-rep">回复 {{ $getUserName(replyUser, replyAnonymous, comment.reply_username) }}：</span>
           


            </template>
            <p class="m-text-box" v-html="formatBody(body)" />
          </article>
        </div>

        <div class="comment-table" @click="toTable">
          {{ title }}
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import markdownIt from 'markdown-it'
  import plusImagePlugin from 'markdown-it-plus-image'
  import ArticleCommentItemChild from './ArticleCommentItemChild'
  import * as api from '@/api/group'

  export default {
    name: 'CommentCard',
    components: {
      // AttachmentType,
      ArticleCommentItemChild,
    },
    props: {
      comment: { type: Object, required: true },
    },
    computed: {
      userName () {
        const name = this.$getUserName(this.user, false, this.comment.username)
        return name
      },
      user () {
        const { user } = this.comment
        return user && user.id ? user : { id: this.comment.user_id }
      },
      replyUser () {
        const { reply } = this.comment
        return reply && reply.id !== undefined ? reply : null
      },
      replyAnonymous () {
        
        return this.comment.reply_anonymous == 1
      },
      body () {
        return this.comment.body || ''
      },
      time () {
        return this.comment.created_at || ''
      },
      anonymous () {
        return this.comment.anonymous == 1
      },
      commentable () {
        return this.comment.commentable || {}
      },
      title () {
        return this.commentable.title || ''
      },
      group_id () {
        return this.commentable.group_id || 0
      },
    },
    mounted () {
      this.$store.commit('SAVE_USER', this.user)
    },
    methods: {
      formatBody (body) {
        return markdownIt({
          html: true,
        })
          .use(plusImagePlugin, `${this.$http.defaults.baseURL}/files/`)
          .render(body)
      },
      toTable () {
        this.$router.push({ path: `/groups/${this.group_id}/posts/${this.commentable.id}` })
      },
    },
  }
</script>

<style lang="less" scoped>
.m-child-comment {
  user-select: auto;
  border: 0;
  position: relative;
}
.m-child-comment::after {
  content: '';
  position: absolute;
  height: 1px;
  width: calc(100% + 20px);
  background: #ededed;
  left: 0;
  bottom: 0;
}
.m-child-comment:last-child::after {
  width: 0;
  height: 0;
}
.attachment {
  color: #63769A
}
.attachment > .img img{
  width: 18px;
}
.attachments_name {
  font-size: 22px;
  max-width: 500px;
  word-break: break-all;
}
.comment-child-count{
  color: #5186EB;
  font-size: 24px;
  margin-top: 20px;
}
.m-avatar {
  &-box-secret {
    width: 56px;
    height: 56px;
  }
}
.m-art-comment-usr {
  position: relative;

  h4 {
    font-size: 26px;
    .sign {
      width: 25px;
      height: 25px;
      margin-left: 10px;
    }
    .wonderful {
      width: 56px;
      margin-left: 10px;
    }
  }

  > div > span {
    font-size: 20px;
    color: #B2B2B2;
  }

  .anonymous-tag {
    padding: 0 15px;
    height: 36px;
    line-height: 36px;
    border: 1px solid #E5E5E5;
    opacity: 1;
    border-radius: 22px;
    color: #999999;
    font-size: 20px;
    position: absolute;
    right: 0;
  }
}
.m-art-comment-con {
  font-size: 28px;
  color: #333;
  display: inline-block;

  .m-art-comment-rep {
    > a {
      color: #5186EB;
    }
  }

  /deep/ .m-text-box {
    display: inline-block;

    img {
      max-width: 100%;
      max-height: 400px;
      object-fit: cover;
    }
  }
}
.comment-table {
  background: #F7F7F7;
  padding: 20px;
  margin-top: 20px;
  font-size: 28px;
  color: #666666;
}
</style>
