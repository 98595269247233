<template>
  <div class="c-user-item" @click="toUserHome">
    <Avatar :user="user" />
    <section class="user-item-body m-text-cut">
      <h2 class="m-text-box m-text-cut">{{ userName }}<UserTitle :user="user" /></h2>
      <p class="m-text-box m-text-cut">{{ user.bio || $t('profile.default_bio') }}</p>
    </section>
<!--    <button
      v-if="!isMine"
      class="chat-btn"
      @click.stop="startSingleChat"
    >
      私信
    </button>-->
  </div>
</template>
<script>
  import { startSingleChat } from '@/vendor/easemob'

  export default {
    name: 'UserItemNew',
    props: {
      user: { type: Object, required: true },
      link: { type: Boolean, default: true },
    },
    data () {
      return {
        loading: false,
      }
    },
    computed: {
      isMine () {
        return this.$store.state.CURRENTUSER.id === this.user.id
      },
      userName () {
        const name = this.$getUserName(this.user)
        return name
      },
    },
    created () {
      this.$store.commit('SAVE_USER', this.user)
    },
    methods: {
      toUserHome () {
        this.link && this.$router.push({ name: 'UserDetail', params: { userId: this.user.id } })
      },
      startSingleChat () {
        startSingleChat(this.user).then(chatId => {
          this.$nextTick(() => {
            this.$router.push({ name: 'ChatRoom', params: { chatId } })
          })
        })
      },
    },
  }
</script>

<style lang='less' scoped>
.c-user-item {
  display: flex;
  align-items: center;
  padding: 30px 20px;
  background-color: #fff;

  & + & {
    border-top: 1px solid #ededed; /* no */
  }

  .user-item-body {
    display: flex;
    flex-direction: column;
    flex: auto;
    margin-left: 30px;
    margin-right: 30px;

    h2 {
      margin: 9px 0;
      font-size: 32px;
    }
    p {
      margin: 9px 0;
      font-size: 28px;
      color: @text-color3;
    }
  }

  .chat-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    width: 94px;
    height: 44px;
    color: #fff;
    background-color: @primary;
    border: 1px solid currentColor; /* no */
    border-radius: 8px;
    white-space: nowrap;
    font-size: 24px;
  }
}
</style>
