<template>
  <Transition
    enter-active-class="animated bounceInRight"
    leave-active-class="animated bounceOutLeft"
  >
    <div class="m-box-model m-pos-f p-signin">
      <!-- <CommonHeader>
        {{ $t('auth.login') }}
        <span slot="left" />
        <RouterLink slot="right" to="/signup">
          {{ $t('auth.register.name') }}
        </RouterLink>
      </CommonHeader> -->

      <main class="m-box-model m-flex-grow1">
        <div class="header-background">
          <img :src="require('@/images/background.png')" class="bgimg" />
        </div>
        <div class="main-card">
          <div class="m-form-row m-main m-inpt">
            <img
              :src="require('@/images/ico_login_account.png')"
              class="userimg"
            />
            <div class="m-input">
              <input
                id="account"
                v-model="account"
                type="text"
                :placeholder="$t('auth.placeholder.account')"
              />
            </div>
            <svg
              v-show="account.length > 0"
              class="m-style-svg m-svg-def"
              @click="account = ''"
            >
              <use xlink:href="#icon-clean" />
            </svg>
          </div>
          <div class="m-form-row m-main m-inpt">
            <img
              :src="require('@/images/ico_login_password.png')"
              class="logoimg"
            />
            <div class="m-input">
              <input
                v-if="eye"
                id="password"
                v-model="password"
                class="password"
                type="text"
                maxlength="16"
                :placeholder="$t('auth.placeholder.password', [6])"
              />
              <input
                v-else
                v-model="password"
                class="password"
                maxlength="16"
                type="password"
                :placeholder="$t('auth.placeholder.password', [6])"
                @keyup.enter="signinByAccount"
              />
            </div>
            <svg class="m-style-svg m-svg-def" @click="eye = !eye">
              <use :xlink:href="eye ? '#icon-eye-open' : '#icon-eye-close'" />
            </svg>
          </div>
          <div class="m-box m-aln-center m-text-box m-form-err-box err-box">
            <span>{{ err | plusMessageFirst }}</span>
          </div>
          <div class="m-form-row" style="border: 0">
            <button
              :disabled="disabled"
              class="m-long-btn m-signin-btn"
              @click="signinByAccount"
            >
              <CircleLoading v-if="loading" />
              <span v-else>{{ $t("auth.login") }}</span>
            </button>
          </div>
        </div>
        <!-- <div class="m-box m-aln-center m-justify-bet other-link">
          <RouterLink tag="span" to="/feeds?type=hot">
            <a>{{ $t("auth.guest") }}</a>
          </RouterLink>
          <RouterLink tag="span" to="/forgot">
            <a>{{ $t("auth.forgot.name") }}</a>
          </RouterLink>
        </div>
        <div class="dynamic-signin">
          <RouterLink to="/signin/dynamic">{{
            $t("auth.use_phone")
          }}</RouterLink>
        </div> -->
        <div class="tips"><span class="star">* </span>仅限官方账号登录</div>
      </main>
    </div>
  </Transition>
</template>

<script>
import { signinByAccount } from "@/api/user.js";
import { signinByWechat } from "@/util/wechat.js";
import * as api from "@/api";

export default {
  name: "Signin",
  data() {
    return {
      err: "",
      eye: false,
      account: "",
      password: "",
      loading: false,
      SHOP_PLUS_ID: process.env.VUE_APP_SHOP_PLUS_ID || 0
    };
  },
  computed: {
    disabled() {
      return (
        this.account.length === 0 || this.password.length === 0 || this.loading
      );
    },
    isWechat() {
      return this.$store.state.BROWSER.isWechat;
    }
  },
  methods: {
    signinByWechat,
    signinByAccount() {
      this.err = "";
      if (this.account.length === 0) {
        this.err = this.$t("auth.error.account");
        return false;
      }

      if (this.password.length < 4) {
        this.err = this.$t("auth.error.password_min", { min: 4 });
        return false;
      }

      this.loading = true;

      signinByAccount({
        login: this.account,
        password: this.password
      }).then(state => {
        this.loading = false;
        state &&
          this.$nextTick(() => {
            const redirect = this.$route.query.redirect;
            if (/^((http|https)?:\/\/)/.test(redirect)) {
              api.getShop(this.SHOP_PLUS_ID).then(({ data }) => {
                let params = data.params || []
                const url = data.url || ''
                params.link = redirect
                params = Object.keys(params)
                  .map(function(key) {
                    return (
                      encodeURIComponent(key) +
                      "=" +
                      encodeURIComponent(params[key])
                    );
                  })
                  .join("&");
                location.href = url + "/Home/Api/check?" + params;
              });
            } else {
              this.$store.dispatch("message/getAllUnreadCount");
              this.$bus.$emit('loginRefresh')
              this.$router.push(redirect || "/home")
            }
          });
      });
    }
  },
}
</script>

<style lang="less" scoped>
.header-background {
  position: relative;
  width: 100%;
  @radio: 750 / 341; // banner 固定的宽高比

  height: calc(~"100vw / @{radio}"); // 由屏幕宽度计算出相应比例的高度
  .bgimg {
    width: 100%;
    height: 100%;
  }
  .logoimg {
    position: absolute;
    top: 55px;
    left: 295px;
    width: 160px;
    height: 76px;
  }
}
.main-card {
  position: relative;
  top: -150px;
  margin: 0 55px;
  padding: 55px 40px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 12px;
}
.p-signin {
  background-color: #f4f5f6;

  .other-link {
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 0 30px;
    font-size: 26px;

    a {
      color: @text-color3;
    }
  }

  .dynamic-signin {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    margin-top: 80px;
  }

  .m-trhsignin {
    position: relative;
    text-align: center;
    padding: 60px 80px;

    &:before {
      position: absolute;
      top: 0;
      left: 80px;
      right: 80px;
      content: "";
      display: block;
      height: 0;
      border-top: 1px solid #ccc; /*no*/
    }

    &:after {
      color: #ccc;
      content: "社交账号登陆";
      display: inline-block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0 20px;
      background-color: #f4f5f6;
    }

    .m-tr-item {
      font-size: 24px;
      line-height: 26px;
      color: @text-color3;

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        background-color: #ffffff;

        .m-svg-def {
          width: 38px;
          height: 38px;
        }
      }
    }
  }
}
.m-inpt {
  border: 1px solid rgba(219, 219, 219, 1);
  opacity: 1;
  border-radius: 4px;
  height: 80px;
  margin-bottom: 30px;
  padding: 0 26px;
  .userimg {
    width: 24px;
    height: 24px;
    margin-right: 24px;
  }
  .logoimg {
    width: 24px;
    height: 28px;
    margin-right: 24px;
  }
}
.err-box {
  height: 50px;
}
.tips {
  position: relative;
  top: -110px;
  color: #999999;
  font-size: 24px;
  text-align: center;
  .star {
    color: red;
  }
}
</style>
