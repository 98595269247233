<template>
  <div class="p-profile">
    <main class="m-box-model">
      <div class="m-box-model m-main header-b">
        <header class="header-box" :style="userBackGround">
          <RouterLink
            tag="img"
            :src="require('@/images/ico_me_edit@2x.png')"
            class="user-set"
            to="/info"
          />
          <RouterLink
            tag="section"
            class="m-box m-aln-center profile-info"
            :to="`/users/${user.id}`"
          >
            <Avatar :user="user" size="big" :check="false" />
            <div
              class="
                m-text-box
                m-flex-grow1
                m-flex-shrink1
                m-flex-base0
                m-pr-user-info
              "
            >
              <h4 class="m-pr-username">
                {{ userName }} {{ user.department_name
                }}<UserTitle :user="user" />
              </h4>
              <p class="m-pr-bio m-text-cut-2">
                {{ user.bio || $t("profile.default_bio") }}
              </p>
            </div>

            <svg class="m-style-svg m-svg-def m-entry-append white">
              <use xlink:href="#icon-arrow-right" />
            </svg>
          </RouterLink>
        </header>
        <!--
        <div class="m-box m-aln-center m-justify-aro m-bt1 followers">
          <RouterLink
            :to="`/users/${user.id}/followers`"
            tag="div"
            class="follower-item"
          >
            <BadgeIcon :count="newFans">
              <a>{{ ~~extra.followers_count | formatNum }}</a>
            </BadgeIcon>
            <p>{{ $t("fans") }}</p>
          </RouterLink>
          <RouterLink
            :to="`/users/${user.id}/followings`"
            tag="div"
            class="follower-item"
          >
            <BadgeIcon count="0">
              <a>{{ ~~extra.followings_count | formatNum }}</a>
            </BadgeIcon>
            <p>{{ $t("follow.name") }}</p>
          </RouterLink>
          <RouterLink :to="`/users/friends`" tag="div" class="follower-item">
            <BadgeIcon count="0">
              <a>{{ ~~user.friends_count | formatNum }}</a>
            </BadgeIcon>
            <p>{{ $t("follow.friend") }}</p>
          </RouterLink>
        </div> -->
      </div>

      <div class="m-box-model m-pr-entrys">
        <ul class="m-box-model m-entry-group">
          <!-- <ProfileItem
            :label="$t('profile.home.name')"
            icon="#icon-profile-home"
            :to="`/users/${user.id}`"
          /> -->

          <ProfileItem
            :label="$t('profile.news.name')"
            icon="ico_mine_post@2x.png"
            :to="`/users/${user.id}`"
          />

          <ProfileItem
            :label="$t('currency.task')"
            icon="ico_mine_task@2x.png"
            to="/currency_task"
          />
          <!-- <ProfileItem
            :label="$t('currency.prize.mydetail')"
            icon="gift.png"
            to="/currency/my/prize"
          /> -->
          <ProfileItem
            :label="$t('profile.collect.name')"
            icon="ico_mine_collect@2x.png"
            to="/profile/collection"
          />
          <ProfileItem
            :label="$t('profile.draft.name')"
            icon="ico_mine_sketch@2x.png"
            to="/profile/draft"
          />
          <ProfileItem
            :label="$t('profile.level.name')"
            icon="ico_mine_level@2x.png"
            to="/profile/level"
          />
          <ProfileItem
            :label="$t('setting.name')"
            icon="ico_mine_settings@2x.png"
            to="/setting"
          />

          <ProfileItem
            :label="$t('currency.prize.mydetail')"
            icon="gift.png"
            to="/currency/my/prize"
          />

          <ProfileItem
            :label="$t('currency.lottery')"
            icon="ico_mine_jiang@2x.png"
            to="/currency_lottery"
          />

          <!-- 积分商城 -->
          <!-- <ProfileItem
          :label="$t('profile.integral.store')"
          icon="ico_mine_settings@2x.png"
          to="/profile/store"
          /> -->
        </ul>

        <!-- <ul class="m-box-model m-entry-group">
          <ProfileItem
            :label="$t('wallet.name')"
            icon="#icon-profile-wallet"
            to="/wallet"
          >
            {{ new_balance }}
          </ProfileItem>

          <ProfileItem
            :label="$t('currency.name')"
            icon="#icon-profile-integral"
            to="/currency"
          >
            {{ sum }}
          </ProfileItem>
        </ul>

        <ul class="m-box-model m-entry-group">
          <ProfileItem
            :label="$t('certificate.name')"
            icon="#icon-profile-approve"
            @click="selectCertType"
          >
            {{ verifiedText }}
          </ProfileItem>
        </ul> -->
      </div>
    </main>
    <FootGuide />
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapActions } from "vuex";
import ProfileItem from "./components/ProfileItem";

export default {
  name: "Profile",
  components: { ProfileItem },
  data() {
    return {
      verifiedText: "",
    };
  },
  created() {
    // this.$matomoTrackPageView(`我的 - 旭辉员工论坛（SPA）`)
  },
  computed: {
    ...mapState({
      newFans: (state) => state.message.user.following || 0,
      newMutual: (state) => state.message.user.mutual || 0,
      user: (state) => state.CURRENTUSER,
      verified: (state) => state.USER_VERIFY,
    }),
    userName() {
      const name = this.$getUserName(this.user);
      return name;
    },
    extra() {
      return this.user.extra || {};
    },
    new_wallet() {
      return this.user.new_wallet || { balance: 0 };
    },
    new_balance() {
      return (this.new_wallet.balance / 100).toFixed(2);
    },
    currency() {
      return this.user.currency || { sum: 0 };
    },
    sum() {
      return this.currency.sum;
    },
    useravatar() {
      const avatar = this.user.avatar || {};
      return avatar.url || null;
    },
    userBackGround() {
      if (this.useravatar) {
        return {
          "background-image": `url("${this.useravatar}")`,
        };
      }
      return {
        "background-color": "rgba(0,0,0,0.8)",
      };
    },
  },
  watch: {
    verified(to) {
      if (to && to.status) to.status = Number(to.status);
      const text = this.$t("certificate.status"); // ['待审核', '通过审核', '被驳回', '未认证']
      if (to && [0, 1, 2].includes(to.status)) {
        return (this.verifiedText = text[to.status]);
      }
      this.verifiedText = text[3];
    },
  },
  mounted() {
    this.$store.dispatch("fetchUserInfo");
    this.$store.dispatch("FETCH_USER_VERIFY");
    this.getUnreadCount();
  },
  methods: {
    ...mapActions("message", {
      getUnreadCount: "getUnreadCount",
    }),
    selectCertType() {
      if (_.isEmpty(this.verified)) {
        const actions = [
          {
            text: this.$t("certificate.user.name"),
            method: () => this.certificate("user"),
          },
          {
            text: this.$t("certificate.org.name"),
            method: () => this.certificate("org"),
          },
        ];
        this.$bus.$emit("actionSheet", actions);
      } else if (this.verified.status === 2) {
        // 被驳回则补充填写表单
        const type = this.verified.certification_name || "user";
        this.certificate(type);
      } else {
        this.$router.push({ path: "/profile/certification" });
      }
    },
    /**
     * 认证
     * @param {string} type 认证类型 (user|org)
     */
    certificate(type) {
      this.$router.push({ path: "/profile/certificate", query: { type } });
    },
  },
};
</script>

<style lang="less" scoped>
.header-b {
  overflow: hidden;
}
.header-box {
  position: relative;
  display: flex;
  align-items: center;
  @radio: 750 / 334; // banner 固定的宽高比

  height: calc(~"100vw / @{radio}"); // 由屏幕宽度计算出相应比例的高度
  padding: 30px;
  transform: translate3d(0, 0, 0);
  background-size: cover;
  background-position: center;
  //背景虚化
  &:after {
    content: "";
    position: absolute;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    top: -15px;
    left: -15px;
    bottom: 0;
    right: 0;
    z-index: -1;
    margin: auto;

    filter: blur(15px);
    // transform: scale(1.1);
    background: inherit;
  }
  .user-set {
    position: absolute;
    top: 24px;
    right: 30px;
    width: 37px;
    height: 39px;
  }
}
.m-pr-user-info {
  color: #ffffff;
  margin-left: 30px;
  margin-right: 30px;
  line-height: 40px;
  .m-pr-username {
    font-size: 32px;
    font-weight: 600;
  }
  .m-pr-bio {
    overflow: hidden;
    max-height: 40 * 2px;
    font-size: 28px;
    text-overflow: ellipsis;
  }
}
.m-pr-entrys {
  margin-top: 30px;
  margin-bottom: 30px;

  .m-entry {
    padding: 0 20px;
  }

  .m-entry-extra {
    margin: 0;

    + .m-entry-append {
      margin-left: 10px;
    }
  }
}

.p-profile {
  .profile-info {
    flex: 1;
  }
  .followers {
    padding: 40px 20px;

    .follower-item {
      flex: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      border-left: 1px solid @border-color; /*no*/

      &:first-child {
        border-left: none;
      }

      a {
        font-size: 32px;
      }

      p {
        margin-top: 15px;
      }

      /deep/ .v-badge-count {
        top: -12px;
        right: -44px;
      }
    }
  }
  .m-entry-prepend {
    color: @primary;
    width: 36px;
    height: 36px;
  }
  .m-entry-append {
    color: #bfbfbf;
    width: 24px;
    height: 24px;
  }
}
</style>

<style lang="less">
.p-profile {
  .follower-item {
    .v-badge-count {
      top: -10px;
      right: -50px;
    }
  }
}
.white {
  color: #fff !important;
  fill: #fff !important;
}
.jo-loadmore {
  .jo-loadmore-head {
    z-index: -1 !important;
  }
}
</style>
