<template>
  <div
    v-if="list.length > 0"
    id="swiper1"
    class="c-banner-advertisement swiper-container"
  >
    <swiper
      v-if="list.length == 1"
      :options="swiperOption"
      class="banner-swipe"
    >
      <swiperSlide v-for="ad in list" :key="`one-${ad.id}`">
        <div class="wrap" @click="to(ad.data.link)">
          <div :style="{ backgroundImage: `url(${ad.data.image})` }" class="ad adpre" />
        </div>
      </swiperSlide>
      <swiperSlide v-for="ad in list" :key="ad.id">
        <a :href="ad.data.link">
          <div class="wrap">
            <div :style="{ backgroundImage: `url(${ad.data.image})` }" class="ad adpre" />
          </div>
        </a>
      </swiperSlide>
      <div slot="pagination" class="swiper-pagination" />
    </swiper>
    <swiper
      v-else-if="list.length > 1"
      :options="swiperOption"
      class="banner-swipe"
    >
      <swiperSlide v-for="ad in list" :key="ad.id">
        <a :href="ad.data.link">
          <div class="wrap">
            <div :style="{ backgroundImage: `url(${ad.data.image})` }" class="ad adpre" />
          </div>
        </a>
      </swiperSlide>
      <div slot="pagination" class="swiper-pagination" />
    </swiper>
  </div>
</template>

<script>
  import './style/swiper.css'
  import 'swiper/dist/css/swiper.css'

  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import * as bootApi from '@/api/bootstrappers.js'

  /**
   * 广告位类型映射表
   */
  const adMap = {
    'home:top': 'group:index:top',
    'group:top': 'group:single',
  }

  export default {
    name: 'NewAd',
    components: {
      swiper,
      swiperSlide,
    },
    props: {
      /**
       * 广告类型
       * 可选值: home:top (首页), group:top (社区),
       * @type {string}
       */
      type: {
        type: String,
        required: true,
        validator: val => Object.keys(adMap).includes(val),
      },
      /**
       * 轮播图轮询时间 ms
       * @type {number}
       */
      loopTime: { type: Number, default: 3000 },
    },
    data () {
      return {
        list: [], // 轮播图列表
        swiperOption: {
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          loop: true, // 无限循环
          slidesPerView: 'auto',
          centeredSlides: true,
          spaceBetween: 10,
          loopAdditionalSlides: 100,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets', // 这个不用变成custom值，就可以之定义分页器样式(可爽)
            bulletElement: 'span', // 设置分页器小圆点标签，默认为span标签
            clickable: true,
            paginationClickable: true,
            notNextTick: true,
            bulletClass: 'bullet-class', // 设置小圆点的类名,包括下面的当前页面导航器的类名，用来写css样式，注意，这段css一定要引在，swiper实例创建之前
            bulletActiveClass: 'bullet-active-class',
          },
        },
      }
    },
    computed: {
      /**
       * 顶部 banner 广告列表
       * @returns {number}
       */
      adTypeId () {
        const adType = this.$store.getters.getAdTypeBySpace(adMap[this.type])
        return adType.id
      },
    },
    created () {
      this.fetchList()
    },
    methods: {
      fetchList () {
        // TODO: @mutoe [api] refactor there with vuex action
        bootApi.getAdsById(this.adTypeId).then(({ data }) => {
          this.list = data.sort((a, b) => a.sort < b.sort)
        })
      },
      to (url) {
        console.log(url)
        return
      },
    },
  }
</script>

<style lang="less" scoped>

//@radio: 414 / 215; // banner 固定的宽高比
@radio: 645 / 283; // banner 固定的宽高比

.c-banner-advertisement {
  .banner-swipe {
    .wrap {

      height: calc(~"80vw / @{radio}"); // 由屏幕宽度计算出相应比例的高度
      position: relative;
      display: block;

      .title {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 30px;
        h2 {
          font-size: 42px;
          font-weight: 600;
          margin-bottom: 5px;
        }
      }
      .subtitle {
      }

      .ad {
        display: flex;
        align-items: center;
        background: no-repeat center / cover;
        width: 100%;
        border-radius: 10px;
        height: calc(~"100vw / @{radio}"); // 由屏幕宽度计算出相应比例的高度
      }

      .adpre {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
#swiper1 {
  box-sizing: content-box;
  padding: 0 0 80px 0;
  width: 100%;
  overflow: hidden !important;
  background-color: #fff;
}

.swiper-container,
.swiper-container2 {
  width: 100%;

  //height: calc(~"80vw / @{radio}"); // 由屏幕宽度计算出相应比例的高度
  overflow: visible !important;
  position: relative;
}
/* 当前 */
#swiper1 .swiper-container .swiper-wrapper .swiper-slide {
  width: 80vw;
}

/* 上一张 *//* 下一张 */
#swiper1 .swiper-container .swiper-wrapper .swiper-slide-prev .wrap .ad, #swiper1 .swiper-container .swiper-wrapper .swiper-slide-next .wrap .ad {
  height: 90%;
  position: relative;
  top: 50%;
  transform: translateY(calc(-50% - 1px));
}

/* 中间的图片 */
.swiper-container .swiper-wrapper .swiper-slide-active .wrap .ad {
  height: calc(~"80vw / @{radio}") !important;
}
.swiper-pagination {
  bottom: -60px !important;
}
</style>
