<template>
  <div class="p-profile">
    <main class="m-box-model">
      <div class="m-box-model m-main">
        <header class="header-box" :style="userBackGround">
          <div class="left" @click="goBack">
            <img :src="require('@/images/back_white@2x.png')" class="m-style-svg m-svg-def ico-back">
          </div>
          <RouterLink
            tag="section"
            class="m-box m-aln-center profile-info"
            :to="`/users/${user.id}`"
          >
            <Avatar :user="user" size="big" />
            <div
              class="m-text-box m-flex-grow1 m-flex-shrink1 m-flex-base0 m-pr-user-info"
            >
              <h4 class="m-pr-username">
                <div>
                  {{ userName }} {{user.department_name}}
                  <UserTitle :user="user" />

                  <div v-if="user.is_vip && user.vip_info" class="vip-tag"><span>{{ user.vip_info.title || '' }}</span></div>
                </div>

               <!-- <span
                  v-if="!isMine"
                  class="justify-chat"
                  @click.stop="startSingleChat"
                >私信</span> -->
                 <span
                  class="department"
                >{{user.department_name}}</span>
              </h4>
              <p class="m-pr-bio m-text-cut-2">
                {{ user.bio || $t("profile.default_bio") }}
              </p>
            </div>
          </RouterLink>
        </header>
        <div v-if="loading" class="m-spinner m-pos-f" />
        <main v-if="showList">
          <nav class="type-switch-bar">
            <span
              :class="{ active: type === 'post' }"
              @click="postRefresh"
            >
              动态({{ user.extra ? user.extra.post_count : 0 }})
            </span>
            <span
              :class="{ active: type === 'comment' }"
              @click="commentRefresh"
            >
              评论({{ user.extra ? user.extra.comments_count : 0 }})
            </span>
          </nav>

          <JoLoadMore
            ref="loadmore"
            @onRefresh="onRefresh"
            @onLoadMore="onLoadMore"
          >
            <ul v-if="type == 'post'" class="feed-list">
              <li
                v-for="(card, index) in list"
                :key="`post-${card.id}-${index}`"
                :data-feed-id="card.id"
              >
                <PostCard
                  v-if="card.user_id"
                  :post="card"
                  :show-user="false"
                />
              </li>
            </ul>

            <ul v-if="type == 'comment'" class="comment-list">
              <li
                v-for="(card, index) in list"
                :key="`post-${card.id}-${index}`"
                :data-feed-id="card.id"
              >
                <CommentCard v-if="card.user_id" :comment="card" />
              </li>
            </ul>
          </JoLoadMore>
        </main>
        <main v-else class="hidden-female">
          <div>
            <img :src="require('@/images/default_secret@2x.png')">
            <p>该用户已经设置隐私保护<br>不显示已发布内容</p>
          </div>
        </main>
      </div>
    </main>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { limit } from '@/api'
  import { mapState, mapActions } from 'vuex'
  import * as userApi from '@/api/user'
  import wechatShare from '@/util/wechatShare'
  import PostCard from '@/page/group/components/PostCard.vue'
  import CommentCard from '@/page/article/components/CommentCard.vue'
  import { getUserPostList, getUserCommentList } from '@/api/group.js'
  import * as chatApi from '@/api/chat.js'

  const TypesMap = ['post', 'comment']

  export default {
    name: 'UserHome',
    components: { PostCard, CommentCard },
    data () {
      return {
        preUID: 0,
        loading: false,
        type: 'post',
        lists: [],
      }
    },
    computed: {
      isWechat () {
        return this.$store.state.BROWSER.isWechat
      },
      currentUser () {
        return this.$store.state.CURRENTUSER
      },
      userId () {
        return ~~this.$route.params.userId
      },
      user: {
        get () {
          return this.$store.getters.getUserById(this.userId, true) || {}
        },
        set (val) {
          this.$store.commit('SAVE_USER', Object.assign(this.user, val))
        },
      },
      userName () {
        const name = this.$getUserName(this.user)
        return name
      },
      extra () {
        return this.user.extra || {}
      },
      isMine () {
        return this.userId === this.currentUser.id
      },
      hidden () {
        return !!this.user.hidden
      },
      showList () {
        if (!this.isMine && this.hidden) {
          return false
        }
        return true
      },
      post_count: {
        get () {
          return this.extra.post_count || 0
        },
        set (val) {
          this.extra.post_count = val
        },
      },
      comment_count: {
        get () {
          return this.extra.comments_count || 0
        },
        set (val) {
          this.extra.comments_count = val
        },
      },
      useravatar () {
        const avatar = this.user.avatar || {}
        return avatar.url || null
      },
      userBackGround () {
        if (this.useravatar) {
          return {
            'background-image': `url("${this.useravatar}")`,
          }
        }
        return {
          'background-color': 'rgba(0,0,0,0.8)',
        }
      },

      list () {
        return this.lists[this.type]
      },
    },
    watch: {
      type (val) {
        if (TypesMap.includes(val)) {
          this.$refs.loadmore.beforeRefresh()
        }
      },
    },
    beforeMount () {
    // if (this.isIosWechat) {
    //   this.reload(this.$router)
    // }
    },
    mounted () {
    // this.$matomoTrackPageView(`${this.userName} 的个人主页 - 旭辉员工论坛（SPA）`)
    },
    activated () {
      const re = this.$route.query.refresh || false
      if (re) {
        this.$router.replace({ query: _.omit(this.$route.query, 'refresh') })

        this.loading = true
        this.lists = []
        this.updateData()
      } else if (this.preUID !== this.userId) {
        this.loading = true
        this.lists = []
        this.updateData()
      } else {
        setTimeout(() => {
          this.loading = false
        }, 300)
      }

      if (this.isWechat) {
        document.title = this.user.name.length > 10 ? `${this.user.name.substr(0, 10)}...` : this.user.name
        // 微信分享
        const shareUrl =
          window.location.origin +
          process.env.BASE_URL.substr(0, process.env.BASE_URL.length - 1) +
          this.$route.fullPath
        const signUrl =
          this.$store.state.BROWSER.OS === 'IOS' ? window.initUrl : shareUrl
        const avatar = this.user.avatar || {}
        wechatShare(signUrl, {
          title: this.user.name,
          desc: (this.user.bio || '').length > 20 ? `${this.user.bio.substr(0, 20)}...` : this.user.bio,
          link: shareUrl,
          imgUrl: avatar.url || '',
        })
      }

      this.preUID = this.userId
    },
    deactivated () {
      this.loading = true
    },
    methods: {
      async onRefresh () {
        const type = this.type
        if (this.userId === 0) {
          return
        }

        if (type == 'post') {
          getUserPostList(this.userId, { limit }).then(({ data }) => {
            this.$set(this.lists, type, data)
            this.$refs.loadmore && this.$refs.loadmore.afterRefresh(data.length < limit)
          })
        } else if (this.type == 'comment') {
          getUserCommentList(this.userId, { limit }).then(({ data }) => {
            this.$set(this.lists, type, data)
            this.$refs.loadmore && this.$refs.loadmore.afterRefresh(data.length < limit)
          })
        }
      },
      async onLoadMore () {
        if (this.userId === 0) {
          return
        }
        const type = this.type
        const offset = this.lists.hasOwnProperty(type) ? this.lists[type].length : 0
        if (type == 'post') {
          getUserPostList(this.userId, { limit, offset }).then(({ data }) => {
            this.lists[this.type] = [...this.lists[this.type], ...data]
            this.$refs.loadmore.afterLoadMore(data.length < limit)
          })
        } else if (this.type == 'comment') {
          getUserCommentList(this.userId, { limit, offset }).then(({ data }) => {
            this.lists[this.type] = [...this.lists[this.type], ...data]
            this.$refs.loadmore.afterLoadMore(data.length < limit)
          })
        }
      },
      postRefresh () {
        this.type = 'post'
        this.$refs.loadmore.beforeRefresh()
      },
      commentRefresh () {
        this.type = 'comment'
        this.$refs.loadmore.beforeRefresh()
      },
      updateData () {
        this.fetchUserInfo()
      },
      fetchUserInfo () {
        userApi.getUserInfoById(this.userId, true).then(user => {
          this.user = Object.assign(this.user, user)
          this.comment_count = user.extra.comments_count || 0
          this.post_count = user.extra.post_count || 0

          // console.log('comment:', this.comment_count , ' ' ,'post:', this.post_count, this.user.extra)

          if (!user.hidden) this.onRefresh()
        }).finally(() => {
          this.loading = false
        })
      },
      startSingleChat () {
        chatApi.createRoom(this.user.id).then(({ data }) => {
          this.$nextTick(() => {
            this.$router.push({ name: 'ChatRoom', params: { chatId: data.id } })
          })
        })
      },
    },
  }
</script>

<style lang="less" scoped>
.p-profile {
  height: 100%;
  background: #fff;
}
.header-box {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  @radio: 750 / 334; // banner 固定的宽高比

  height: calc(~"100vw / @{radio}"); // 由屏幕宽度计算出相应比例的高度
  padding: 30px;
  transform: translate3d(0, 0, 0);
  background-size: cover;
  background-position: center;

  > .left {
    position: absolute;
    left: 30px;
    top: 20px;
  }
  //背景虚化
  &:after {
    content: "";
    position: absolute;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    top: -15px;
    left: -15px;
    bottom: 0;
    right: 0;
    z-index: -1;
    margin: auto;

    filter: blur(15px);
    // transform: scale(1.1);
    background: inherit;
  }
}
.profile-info {
  width: 100%;
  align-items: unset;
  margin-top: 80px;

  .m-pr-user-info {
    color: #ffffff;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 40px;

    .m-pr-username {
      font-size: 32px;
      font-weight: 600;
      position: relative;

      .justify-chat {
        width: 150px;
        height: 48px;
        line-height: 48px;
        background: @primary;
        text-align: center;
        color: #ffffff;
        border-radius: 4px;
        position: absolute;
        top: calc(50% - 24px);
        right: 0;
      }
      .department{
        font-size: .25rem;
      }
      .vip-tag {
        margin: 20px 0 10px;
        font-size: 22px;
        span {
          padding: 0 20px;
          height: 40px;
          line-height: 40px;
          background: rgba(255,255,255,0.3);
          border-radius: 20px;
        }
      }
    }
    .m-pr-bio {
      overflow: hidden;
      max-height: 40 * 2px;
      font-size: 24px;
      text-overflow: ellipsis;
      color: rgba(255,255,255,0.7);
      margin-top: .15rem;
    }
  }
}

.type-switch-bar {
  position: sticky;
  top: 0;
  z-index: 500;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-color: #fff;
  white-space: nowrap;
  justify-content: unset;
  border-bottom: 1px solid #E5E5E5;

  > span {
    width: auto;
  }
  > span.active {
    color: #1A1A1A;
  }

  > span.active::after {
    height: 0;
  }

}

.feed-list {
  background: #f7f7f7;

  > li {
    margin-bottom: 20px;
  }
}
.comment-list {
  background: #fff;
}

.hidden-female {
  > div {
    margin: 300px auto;
    text-align: center;

    > img {
      width: 225px;
    }

    > p {
      font-size: 24px;
      line-height: 41px;
      color: rgba(178,178,178,1);
    }
  }
}

</style>
