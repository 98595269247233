<template>
  <header class="search-bar-box">
    <div class="c-search-bar">
      <form class="input-wrap" onsubmit="return false">
        <img :src="require('@/images/search@2x.png')" class="m-style-img">
        <input
          ref="searchTxt"
          :value="value"
          :placeholder="placeholder"
          type="search"
          @input="onInput"
        >
      </form>

      <a class="btn-cancel" @click.prevent.stop="onBackClick">
        {{ $t('cancel') }}
      </a>
    </div>
    <div v-if="history && (historys.length > 0 && historys[0].length)" class="history-list">
      <ul>
        <li
          v-for="(history, index) in historys"
          v-if="history"
          :key="index"
          @click="getKeyword(history)"
        >
          <div class="his-l">
            <img :src="require('@/images/history@2x.png')">
            <span>{{ history }}</span>
          </div>
          <div class="his-r" @click.prevent.stop="removeHistory(history)">
            <img :src="require('@/images/delete@2x.png')">
          </div>
        </li>
        <li class="remove-all" @click="removeAllHistory">清空搜索历史</li>
      </ul>
    </div>
  </header>
</template>

<script>
  import _ from 'lodash'
  import { noop } from '@/util'
  import i18n from '@/i18n'

  export default {
    name: 'SearchBar',
    props: {
      value: { type: String, default: '' },
      placeholder: { type: String, default: i18n.t('search') },
      back: { type: Function, default: noop },
      history: { type: Boolean, default: true },
    },
    data () {
      return {
        historyList: [],
        historys: [],
      }
    },
    created () {
      this.$nextTick((x) => {
        this.$refs.searchTxt.focus()
      })
    },
    mounted () {
      if (this.history) {
        // 如果本地存储的数据historyList有值，直接赋值给data中的historyList
        const historyList = JSON.parse(this.$lstore.getData('SEARCH_HISTORYLIST', true))
        if (historyList) {
          this.historyList = this.historys = historyList
        }
      }
    },
    methods: {
      onBackClick () {
        if (this.back === noop) this.goBack()
        else this.back()
      },
      onInput: _.debounce(function (event) {
        const keyword = event.target.value.trim()

        if (this.history) {
          // this.historys = this.historyList.filter(item => {
          //   return item.indexOf(keyword) != -1
          // })
          // if (this.historys.length > 0) {
          //   return
          // }
          this.historys = []

          // 搜索记录
          if (!this.historyList.includes(keyword)) {
            this.historyList.unshift(keyword)
            this.$lstore.setData('SEARCH_HISTORYLIST', JSON.stringify(this.historyList), true)
          } else {
            const i = this.historyList.indexOf(keyword)
            this.historyList.splice(i, 1)
            this.historyList.unshift(keyword)
            this.$lstore.setData('SEARCH_HISTORYLIST', JSON.stringify(this.historyList), true)
          }
        }

        this.$emit('input', keyword)
      }, 600),
      getKeyword (keyword) {
        if (this.history) {
          // this.historys = this.historyList.filter(item => {
          //   return item.indexOf(keyword) != -1
          // })
          // if (this.historys.length > 0) {
          //   return
          // }
          this.historys = []

          // 搜索记录
          if (!this.historyList.includes(keyword)) {
            this.historyList.unshift(keyword)
            this.$lstore.setData('SEARCH_HISTORYLIST', JSON.stringify(this.historyList), true)
          } else {
            const i = this.historyList.indexOf(keyword)
            this.historyList.splice(i, 1)
            this.historyList.unshift(keyword)
            this.$lstore.setData('SEARCH_HISTORYLIST', JSON.stringify(this.historyList), true)
          }
        }

        this.$emit('input', keyword)
      },
      removeHistory (keyword) {
        const i = this.historyList.indexOf(keyword)
        this.historyList.splice(i, 1)
        this.$lstore.setData('SEARCH_HISTORYLIST', JSON.stringify(this.historyList), true)
      },
      removeAllHistory () { // 清空所有历史搜索记录
        this.$lstore.removeData('SEARCH_HISTORYLIST', true)
        this.historys = []
        this.historyList = []
      },
    },
  }
</script>

<style lang="less" scoped>
.search-bar-box {
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 99;
}
.c-search-bar {
  flex: none;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 90px;
  padding: 0 30px;
  font-size: 32px;
  z-index: 1;

  .input-wrap {
    flex: auto;
    display: flex;
    align-items: center;
    background-color: #F6F6F6;
    padding: 10px;
    border-radius: 31px;
    height: 62px;

    .m-style-img {
      width: 27px;
      height: 27px;
      margin: 0 15px 0 24px;
    }

    input {
      color: #7c7c7c;
      flex: auto;
      background-color: transparent;
      font-size: 26px;
    }
  }

  .btn-cancel {
    min-width: 2em;
    margin-left: 20px;
    text-align: right;
    font-size: 30px;
  }
}

.history-list {
  background: #fff;

  li {
    width: 100%;
    height: 80px;
    line-height: 80px;
    display: flex;
    padding: 0 30px;
    border-bottom: 1px solid #EDEDED;

    .his-l {
      font-size: 30px;
      color: #999999;
      display: flex;

      > img {
        width: 30px;
        height: 30px;
        margin-right: 28px;
        margin-top: 25px;
      }
    }

    .his-r {
      margin-left: auto;

      > img {
        width: 20px;
        height: 20px;
      }
    }

  }

  li.remove-all {
    width: 100%;
    height: 80px;
    display: block;
    line-height: 80px;
    text-align: center;
    font-size: 30px;
    color: #666666;
  }
}
</style>
