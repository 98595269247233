<template>
  <div class="p-feed">
    <main class="feed-main">
      <div class="headerBox">
        <div class="search" @click="goSearch">
          <img :src="require('@/images/search@2x.png')">
          <span>搜索</span>
        </div>
      </div>
      <!-- H5首页顶部广告位 -->
      <NewAd type="home:top" />
      <!-- <span slot="left" /> -->
      <nav
        id="type-switch-bar"
        class="type-switch-bar"
        :class="{ 'switch-bar-bar-before': barBefore, 'switch-bar-bar-after': barAfter, 'no-tab': topTxt==='下拉刷新'}"
        @scroll="navScrollEvent"
      >
        <span
          :class="{ active: tagType === 'new' }"
          @click="tagType = 'new'"
        >
          {{ $t("newest") }}
        </span>
        <span
          :class="{ active: tagType === 'hot' }"
          @click="tagType = 'hot'"
        >
          {{ $t("hot") }}
        </span>
        <span
          v-for="tag in tags"
          :key="tag.id"
          :class="{ active: tagType == tag.id }"
          @click="tagType = tag.id"
        >
          {{ tag.name }}
        </span>
      </nav>
      <JoLoadMore
        ref="loadmore"
        @onRefresh="onRefresh"
        @onLoadMore="onLoadMore"
      >
        <ul v-gif-play class="feed-list">
          <li
            v-for="(card, index) in pinneds"
            :key="`pinned-${tagType}-${card.id}-${index}`"
            :data-feed-id="card.id"
          >
            <PostCard
              :post="card"
              :tag-router="true"
              :pinned="true"
            />
          </li>
          <li
            v-for="(card, index) in list"
            :key="`post-${tagType}-${card.id}-${index}`"
            :data-feed-id="card.id"
          >
            <PostCard :post="card" :tag-router="true" />
          </li>
        </ul>
      </JoLoadMore>
    </main>
    <FootGuide />
  </div>
</template>

<script>
  // import { mapGetters } from 'vuex'
  // import { limit } from '@/api'
  import PostCard from '@/page/group/components/PostCard.vue'
  import {getPostList, getPostOneList} from '@/api/group.js'
  import * as DD from 'dingtalk-jsapi'

  import NewAd from '@/components/advertisement/NewAd.vue'
  import {mapState} from "vuex";

  const TagTypesMap = ['new', 'hot']

  export default {
    name: 'Home',
    components: { PostCard, NewAd },
    data () {
      return {
        tags: [],
        pinneds: [],
        lists: [],
        barBefore: false,
        barAfter: false,
        topTxt: '',
        leavel: false,
      }
    },
    computed: {
      ...mapState(['CURRENTUSER']),
      list () {
        return this.lists[this.tagType]
      },
      tagType: {
        get () {
          return this.$route.query.type || 'new'
        },
        set (val) {
          const { query } = this.$route
          this.$router.replace({ query: { ...query, type: val } })
        },
      },
      param () {
        const param = {
          limit: 5,
        }
        switch (this.tagType) {
          case 'new':
            param.type = 1
            break
          case 'hot':
            param.type = 2
            break
          default:
            param.tag_content = this.tagType
            break
        }

        return param
      },
    },
    watch: {
      tagType (val) {
        if (this.leavel) return
        this.topTxt = this.$refs.loadmore.topTxt
        val = val === 'new' || val === 'hot' ? val : parseInt(val)
        if (TagTypesMap.includes(val)) {
          this.noMoreData = false
          this.pinneds = []
          this.$set(this.lists, val, [])
          // this.getLists()
          this.$refs.loadmore.beforeRefresh()
        }
      },
    },
    async created () {
      this.fetchTags()

      this.$bus.$on('updatePost', (id,type) => {
        for (var i = 0; i < this.pinneds.length; i++) {
          if(this.pinneds[i].id === id) {
            switch(type) {
              case 'like':
                this.pinneds[i].liked = true
                this.pinneds[i].likes_count += 1
                if(this.pinneds[i].likes.every(item=>item.user.id !== this.CURRENTUSER.id)) {
                  this.pinneds[i].likes.push({user:{id:this.CURRENTUSER.id,name:this.CURRENTUSER.name}})
                }
                break;
              case 'unlike':
                this.pinneds[i].liked = false
                this.pinneds[i].likes_count -= 1
                console.log(this.pinneds[i])
                for (var j = 0; j < this.pinneds[i].likes.length; j++) {
                  if(this.pinneds[i].likes[j].user.id === this.CURRENTUSER.id) {
                    this.pinneds[i].likes.splice(j,1)
                    break
                  }
                }
                break
                case 'comment':
                  getPostOneList({ post_id: id }).then(({ data }) => {
                    this.pinneds[i] = data
                  })
                  break
            }
            break
          }
        }
        for (var n = 0; n < this.list.length; n++) {
          if(this.list[n].id === id) {
            switch(type) {
              case 'like':
                this.list[n].liked = true
                this.list[n].likes_count += 1
                if(this.list[n].likes.every(item=>item.user.id !== this.CURRENTUSER.id)) {
                  this.list[n].likes.push({user:{id:this.CURRENTUSER.id,name:this.CURRENTUSER.name}})
                }
                break;
              case 'unlike':
                console.log(this.list[n])
                this.list[n].liked = false
                this.list[n].likes_count -= 1
                for (let m = 0; m < this.list[n].likes.length; m++) {
                  if(this.list[n].likes[m].user.id === this.CURRENTUSER.id) {
                    console.log(this.list[n].likes[m])
                    this.list[n].likes.splice(m,1)
                    break
                  }
                }
                break
              case 'comment':
                getPostOneList({ post_id: id }).then(({ data }) => {
                  this.list[n] = data
                })
                break
            }
            break
          }
        }
      })
    },
    mounted () {
      this.$refs.loadmore.beforeRefresh()

    // this.getLists()
    },
    async activated () {
      this.leavel = false

      this.$bus.$on('loginRefresh',()=>{
        // 刷新数据
        this.tags = []
        this.pinneds = []
        this.lists = []
        this.fetchTags()
        this.$refs.loadmore.beforeRefresh()
      })

      // if (process.env.VUE_APP_DING_CORP_ID && DD.env.platform !== 'notInDingTalk') {
      //   DD.ready(function () {
      //     DD.biz.navigation.setTitle({
      //       title: document.title,
      //     })
      //   })
      // }
    // window.addEventListener("scroll", this.onScroll);

      // 刷新数据
      // if (this.refresh) {
      //   this.tags = []
      //   this.pinneds = []
      //   this.lists = []
      // }
      //
      // this.fetchTags()
      // this.$refs.loadmore.beforeRefresh()

    },
    deactivated() {
      this.leavel = true
    },
    methods: {
      async onRefresh () {
        await getPostList(this.param).then(({ data: { posts = [], pinneds = [] } }) => {
          this.pinneds = pinneds
          this.topTxt = ''
          this.$set(this.lists, this.tagType, posts)
          if (this.$refs.loadmore) this.$refs.loadmore.afterRefresh(posts.length < 5)
        })
      },
      onLoadMore () {
        const offset = this.lists.hasOwnProperty(this.tagType) ? this.lists[this.tagType].length : 0
        getPostList({ ...this.param, offset }).then(({ data: { posts = [], pinneds = [] } }) => {
          this.pinneds = [...this.pinneds, ...pinneds]
          this.topTxt = ''
          this.$set(this.lists, this.tagType, [...this.lists[this.tagType], ...posts])
          this.$refs.loadmore.afterLoadMore(posts.length < 5)
        })
      },
      getLists (more = false) {
        if (this.fetchFeeding) return
        this.fetchFeeding = true
        const offset = this.lists.hasOwnProperty(this.tagType) ? this.lists[this.tagType].length : 0

        getPostList({ ...this.param, offset }).then(({ data: { posts = [], pinneds = [] } }) => {
          if (offset === 0) {
            this.pinneds = pinneds
            this.$set(this.lists, this.tagType, posts)
          } else {
            this.pinneds = [...this.pinneds, ...pinneds]
            this.$set(this.lists, this.tagType, [...this.lists[this.tagType], ...posts])
          }

          this.fetchFeeding = false
          this.noMoreData = posts.length < 5
        })
      },
      fetchTags () {
        this.$http.get('/1/tags').then(({ data: { hot, list } }) => {
          this.tags = list
          list.map((item) => {
            TagTypesMap.push(item.id)
          })
          setTimeout(() => {
            const event = this.$el.querySelector('#type-switch-bar')
            if (event.scrollWidth - event.scrollLeft - 10 > event.offsetWidth) {
              this.barAfter = true
            }
          }, 300)
        })
      },
      goSearch () {
        this.$router.push('/search')
      },
      navScrollEvent (event) {
        if (event.target.scrollLeft > 10) {
          this.barBefore = true
        } else {
          this.barBefore = false
        }
        if (event.target.scrollWidth - event.target.scrollLeft - 10 > event.target.offsetWidth) {
          this.barAfter = true
        } else {
          this.barAfter = false
        }
      },
      onScroll: _.debounce(function () {
        const _this = this
        window.onscroll = function () {
          // 变量scrollTop是滚动条滚动时，距离顶部的距离
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          // 变量windowHeight是可视区的高度
          const windowHeight = document.documentElement.clientHeight || document.body.clientHeight
          // 变量scrollHeight是滚动条的总高度
          const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
          // 滚动条到底部的条件
          if (scrollTop + windowHeight + 300 >= scrollHeight) {
            // 到了这个就可以进行业务逻辑加载后台数据了
            if (!_this.noMoreData) {
              _this.getLists(true)
            }
          }
        }
      }, 1000 / 60),
    },
  }
</script>

<style lang="less" scoped>
.p-feed {
  position: relative;
  .feed-list > li + li {
    margin-top: 20px;
  }
}
.no-tab{
  pointer-events: none;
}
.type-switch-bar {
  position: sticky;
  top: 0;
  z-index: 2;
  justify-content: flex-start;
  margin: 20px 0;
  background-color: #fff;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 10px;
  display: block;
}
.type-switch-bar::-webkit-scrollbar {
  display: none;
}
.switch-bar-bar-before::before {
  content: "";
  position: fixed;
  left: 0;
  background: linear-gradient(90deg,rgba(255,255,255,1) 0%,rgba(255,255,255,0.1) 100%);
  opacity: 1;
  width: 1rem;
  z-index: inherit;
  height: 95px;
}
.switch-bar-bar-after::after {
  content: "";
  position: fixed;
  right: 0;
  background: linear-gradient(90deg,rgba(255,255,255,0.1) 0%,rgba(255,255,255,1) 100%);
  opacity: 1;
  width: 1rem;
  z-index: inherit;
  height: 95px;
}
.type-switch-bar > span {
  width: auto;
  margin: 0 20px;
  color: #333333;
}
.type-switch-bar > span.active {
  color: @primary;
}
.type-switch-bar > span.active::after {
  background-color: transparent;
}
.headerBox {
  width: 100%;
  height: 110px;
  padding: 24px 30px;
  box-sizing: border-box;
  background-color: #fff;
  .search {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #a6a6a6;
    font-size: 26px;
    border-radius: 31px;
    background-color: #f6f6f6;
    img {
      width: 27px;
      height: 27px;
      margin: 0 15px 0 24px;
    }
  }
}
</style>
