<template>
  <div class="p-group-search">
    <SearchBar v-model="keyword" class="search-bar" />

    <NavTab :nav="nav" class="nav-tab" />
    <main :class="loading?'':'J-hidden'">
      <JoLoadMore
      ref="loadmore"
      :auto-load="false"
      :show-bottom="list.length > 0 "
      class="loadmore"
      @onRefresh="onSearchInput"
      @onLoadMore="onLoadMore"
    >
      <template v-if="type === 'posts'">
        <div v-if="keyword" class="post-list">
          <PostCard
            v-for="post in list"
            :key="post.id"
            class="post-card"
            :post="post"
          />
        </div>
      </template>

      <template v-else-if="type === 'users'">
        <div v-if="keyword" class="user-list">
          <UserItemNew
            v-for="user in list"
            :key="user.id"
            :user="user"
          />
        </div>
      </template>
    </JoLoadMore>
    </main>

<!--    <p v-show="loading" class="load-more-ph m-text-c mt10">正在搜索...</p>-->
    <template v-if="noResult && keyword && !list.length">
      <div v-if="type === 'posts'" class="no-users">
        <p> 未找到相关动态～</p>
        <button @click="$router.push({name: 'groupCreatePost'})">去发帖</button>
      </div>
      <div v-else class="no-users">
        <p> 未找到相关用户</p>
      </div>
    </template>
  </div>
</template>

<script>
  import * as groupApi from '@/api/group'
  import * as userApi from '@/api/user.js'
  import SearchBar from '@/components/common/SearchBar.vue'
  import NavTab from '@/components/common/NavTab.vue'
  import PostCard from '@/page/group/components/PostCard.vue'
  import UserItemNew from '@/components/UserItemNew.vue'

  const nav = [
    { name: 'posts', label: '动态', route: { query: { type: 'posts' } } },
    { name: 'users', label: '用户', route: { query: { type: 'users' } } },
  ]

  export default {
    name: 'Search',
    components: {
      SearchBar,
      NavTab,
      PostCard,
      UserItemNew,
    },
    data () {
      return {
        keyword: '',
        list: [],
        loading: false,
        noResult: false,
        nav,
      }
    },
    computed: {
      type () {
        return this.$route.query.type || 'posts'
      },
    },
    watch: {
      keyword (val, oldVal) {
        if (val.trim() === '') return (this.list = [])
        if (val.trim() === oldVal.trim()) return
        this.$refs.loadmore.beforeRefresh()
      },
      type () {
        this.list = []
        if (this.keyword) {
          this.$refs.loadmore.beforeRefresh()
        } else {
          this.onSearchInput()
        }
      },
    },
    created () {
      // 如果不带参 默认搜索动态
      if (!this.$route.query.type) this.$route.query.type = 'posts'
    },
    methods: {
      async onSearchInput () {
        if (!this.keyword) return
        this.loading = true
        this.noResult = false
        const { data } = await this.search()
        this.list = data
        this.noResult = !this.list.length
        this.$refs.loadmore.afterRefresh(data.length < 15)
        this.loading = false
      },

      async onLoadMore () {
        if (!this.keyword) return
        this.loading = true
        const { data } = await this.search(this.list.length)
        this.list.push(...data)
        this.$refs.loadmore.afterLoadMore(data.length < 15)
        this.loading = false
      },

      search (offset) {
        if (this.type === 'users') {
          return userApi.searchUserByKey(this.keyword, offset)
        } else {
          const params = { keyword: this.keyword, offset }
          return groupApi.searchPosts(params)
        }
      },
    },
  }
</script>

<style lang="less" scoped>
.J-hidden{
  overflow: hidden;
}
.p-group-search {
  height: 100%;
  background: #fff;

  .loadmore {
    padding-top: 90+90px;
    height: 100%;
  }

  .post-list {
    .post-card {
      margin-bottom: 10px;
    }
  }

  .nav-tab {
    position: fixed;
    top: 90px;

    /deep/ ul {
      display: flex;
      padding: 20px 0;

      > li {
        display: block;
        width: 100%;
        font-size: 0.28rem;
        color: #999;
        text-align: center;
      }
      > li:last-child {
        border-left: 1px solid #E5E5E5;
      }
      > li.router-link-exact-active {
        color: #1A1A1A;
        border-bottom: 0;
      }
    }
  }
  .m-no-find {
    position: fixed;
    top: 0;
    width: 100%;
  }
  .no-users {
    padding-top: 180px;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;

    > button {
      background-color: @primary;
      color: #fff;
      border-radius: .08rem;
      padding: .15rem 1.2rem;
      font-size: .32rem;
      margin-top: .3rem;
    }
  }
}
</style>
