import i18n from "@/i18n";

/**
 * 积分模块 (和 wallet 使用一致的模块合并到打包)
 */
const CurrencyLottery = () =>
  import(/* webpackChunkName: 'wallet' */ "@/page/currency/CurrencyLottery.vue");
const CurrencyTask = () =>
  import(/* webpackChunkName: 'wallet' */ "@/page/currency/CurrencyTask.vue");

const Currency = () =>
  import(/* webpackChunkName: 'wallet' */ "@/page/currency/Currency.vue");
const CurrencyRecharge = () =>
  import(
    /* webpackChunkName: 'wallet' */ "@/page/currency/CurrencyRecharge.vue"
  );
const CurrencyWithdraw = () =>
  import(
    /* webpackChunkName: 'wallet' */ "@/page/currency/CurrencyWithdraw.vue"
  );
const CurrencyDetail = () =>
  import(/* webpackChunkName: 'wallet' */ "@/page/currency/CurrencyDetail.vue");
const CurrencyJournalDetail = () =>
  import(
    /* webpackChunkName: 'wallet' */ "@/page/currency/CurrencyJournalDetail.vue"
  );
const CurrencyPrizeDetail = () =>
  import(/* webpackChunkName: 'wallet' */ "@/page/currency/PrizeDetail.vue");
const CurrencyMyPrize = () =>
  import(/* webpackChunkName: 'wallet' */ "@/page/currency/MyPrize.vue");
const CurrencyMyPrizeDetail = () =>
  import(/* webpackChunkName: 'wallet' */ "@/page/currency/MyPrizeDetail.vue");
export default [
  {
    path: "/currency_task",
    component: CurrencyTask,
  },
  {
    path: "/currency_lottery",
    component: CurrencyLottery,
  },
  {
    path: "/currency",
    component: Currency,
    meta: {
      title: i18n.t("profile.task.name"), // 积分名称为动态名称，在组件内替换
      requiresAuth: true,
      keepAlive: true,
    },
  },
  {
    path: "/currency/prize/detail",
    component: CurrencyPrizeDetail,
    meta: {
      title: i18n.t("currency.prize.detail"),
    },
  },
  {
    path: "/currency/my/prize",
    component: CurrencyMyPrize,
    meta: {
      title: i18n.t("currency.prize.my"),
      keepAlive: true,
    },
  },
  {
    path: "/currency/my/prize/detail",
    component: CurrencyMyPrizeDetail,
    meta: {
      title: i18n.t("currency.prize.mydetail"),
    },
  },
  {
    name: "currencyRecharge",
    path: "/currency/recharge",
    component: CurrencyRecharge,
    meta: {
      title: i18n.t("currency.recharge.name"),
    },
  },
  {
    path: "/currency/withdraw",
    component: CurrencyWithdraw,
    meta: {
      title: i18n.t("currency.withdraw.name"),
    },
  },
  {
    path: "/currency/detail",
    component: CurrencyDetail,
    meta: {
      title: i18n.t("currency.detail"),
    },
  },
  {
    path: "/currency/journal-detail",
    component: CurrencyJournalDetail,
    meta: {
      title: i18n.t("currency.detail"),
    },
  },
];
