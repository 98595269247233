import _ from 'lodash'
import * as api from '@/api/message'
import * as groupApi from '@/api/group'
import * as feedApi from '@/api/feeds'
import * as newsApi from '@/api/news'

export default {
  namespaced: true,

  state: {
    system: {
      first: {
        data: {},
      },
    },
    comment: {},
    like: {},
    follow: {},
    user: {},
    at: {},
    chat: {},
    // audits: {
    //   feedCommentPinned: [],
    //   newsCommentPinned: [],
    //   postPinned: [],
    //   postCommentPinned: [],
    //   groupJoined: [],
    // },
  },

  getters: {
    unreadMessage: state => Object.keys(_.pick(state, ['comment', 'like', 'system', 'at', 'chat']))
      .reduce((sum, key) => sum + ((state[key]).badge || 0), 0),

    // unreadAudits: state => Object.keys(state.audits)
    //   .reduce((sum, key) => sum + _.filter(state.audits[key], item => item.status === 0 || item.expires_at === null).length, 0),
  },

  mutations: {
    SAVE_NOTIFICATIONS (state, notifications) {
      for (const key in _.pick(state, ['comment', 'like', 'system', 'at', 'chat'])) {
        state[key] = notifications[key]
      }
    },
    // SAVE_AUDIT (state, { type, list, append = false }) {
    //   if (append) {
    //     state.audits[type].push(...list)
    //   } else {
    //     state.audits[type] = list
    //   }
    // },
    SAVE_USER (state, user) {
      state.user = user
    },

  },

  actions: {
    async getNotificationStatistics ({ commit }) {
      const { data } = await api.getNotificationStatistics()
      commit('SAVE_NOTIFICATIONS', data)
    },

    async getUnreadCount ({ commit }) {
      const { data } = await api.getUnreadCounts()
      commit('SAVE_USER', data)
    },

    // async getFeedCommentPinnedList ({ commit }) {
    //   const { data: list } = await feedApi.getFeedCommentPinneds()
    //   commit('SAVE_AUDIT', { type: 'feedCommentPinned', list })
    // },

    // async getNewsCommentPinnedList ({ commit }) {
    //   const { data: list } = await newsApi.getNewsCommentPinneds()
    //   commit('SAVE_AUDIT', { type: 'newsCommentPinned', list })
    // },

    // async getGroupJoinedList ({ commit }, payload = {}) {
    //   const { data: list } = await groupApi.getGroupAudits(payload)
    //   commit('SAVE_AUDIT', { type: 'groupJoined', list })
    // },

    // async getPostPinnedList ({ commit }) {
    //   const { data: list } = await groupApi.getPostAudits({})
    //   commit('SAVE_AUDIT', { type: 'postPinned', list })
    // },

    // async getPostCommentPinnedList ({ commit }) {
    //   const { data: list } = await groupApi.getPostCommentAudits({})
    //   commit('SAVE_AUDIT', { type: 'postCommentPinned', list })
    // },

    async getAllUnreadCount ({ dispatch }) {
      await Promise.all([
        dispatch('getNotificationStatistics'),
        dispatch('getUnreadCount'),
      ])
      // dispatch('getFeedCommentPinnedList')
      // dispatch('getNewsCommentPinnedList')
      // dispatch('getGroupJoinedList')
      // dispatch('getPostPinnedList')
      // dispatch('getPostCommentPinnedList')
    },
  },
}
